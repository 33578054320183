import { gql } from '@apollo/client';

export const VERIFY_EMAIL = gql`
  mutation VerifyEmail($code: String!) {
    verifyEmail(code: $code) {
      success
    }
  }
`;

export interface VerifyEmailInput {
  code: string;
}

export interface VerifyEmailData {
  verifyEmail: {
    success: boolean;
  };
}
