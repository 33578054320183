import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { requiredCapabilities } from './util';

const NotificationCapabilitySupport = ({
  name,
  map,
}: {
  name: string;
  map: Navigator | Window;
}) => {
  return (
    <Flex alignItems={'center'} gap={2}>
      <Text flexBasis={150}>{name}</Text>
      {name in map ? <CheckIcon w={4} h={4} /> : <CloseIcon w={3} h={3} />}
    </Flex>
  );
};

export const NotificationsUnsupported = () => {
  const { t } = useTranslation();

  return (
    <Flex flexDir={'column'} gap={4} justify="center" flex={1}>
      <Text>{t('notifications.unsupported.description')}</Text>
      <Flex flexDir={'column'} gap={2} ml={'1rem'}>
        {requiredCapabilities.map(([key, value]) => {
          return <NotificationCapabilitySupport name={key} map={value} />;
        })}
      </Flex>
    </Flex>
  );
};
