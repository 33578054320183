import { gql } from '@apollo/client';

export const SET_TRANSACTION_HIDDEN = gql`
  mutation SetTransactionHidden($transactionId: String!, $hidden: Boolean!) {
    setTransactionHidden(transactionId: $transactionId, hidden: $hidden) {
      id
      hidden
    }
  }
`;

export interface SetTransactionHiddenInput {
  transactionId: string;
  hidden: boolean;
}

export interface SetTransactionHiddenData {
  setTransactionHidden: {
    id: string;
    hidden: boolean;
    __typename: 'Transaction';
  };
}
