import { Flex, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { NotificationsSupported } from './NotificationsSupported';
import { NotificationsUnsupported } from './NotificationsUnsupported';
import { isNotificationSupported } from './util';

export const NotificationsPage = () => {
  const { t } = useTranslation();

  return (
    <Flex flexDir={'column'} gap={6}>
      <Heading alignSelf={'flex-start'}>{t('notifications.title')}</Heading>
      {isNotificationSupported() ? <NotificationsSupported /> : <NotificationsUnsupported />}
    </Flex>
  );
};
