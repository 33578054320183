import { Button, Flex, Heading, Skeleton, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNotificationPermission, useNotificationSubscription } from './util';

const PermissionDenied = () => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection={'column'} gap={2}>
      <Heading size="md">{t('notifications.supported.denied.title')}</Heading>
      <Text>{t('notifications.supported.denied.description')}</Text>
    </Flex>
  );
};

const PermissionGrantedOrPrompt = () => {
  const { t } = useTranslation();
  const { subscription, loading, unsubscribe, subscribe, isErrored } =
    useNotificationSubscription();
  const isLoaded = subscription !== undefined;

  const buttonText = subscription
    ? t('notifications.unsubscribeButtonText')
    : t('notifications.subscribeButtonText');

  const titleText = subscription
    ? t('notifications.supported.granted.title')
    : t('notifications.supported.prompt.title');

  const descriptionText = subscription
    ? t('notifications.supported.granted.enabledDescriptionText')
    : t('notifications.supported.prompt.description');

  return (
    <Flex flex={1} flexDirection="column" gap={6}>
      <Skeleton isLoaded={isLoaded}>
        <Flex flexDirection={'column'} gap={2}>
          <Heading size="md">{titleText}</Heading>
          <Text>{descriptionText}</Text>
        </Flex>
      </Skeleton>
      <Skeleton isLoaded={isLoaded} maxW="14rem" alignSelf={'flex-end'}>
        <Flex flexDir={'column'} gap={1}>
          <Button
            onClick={subscription ? unsubscribe : subscribe}
            isLoading={loading}
            variant={subscription ? 'outline' : 'solid'}
            colorScheme={subscription ? 'gray' : undefined}
            w="full"
          >
            {buttonText}
          </Button>
          {isErrored && <Text textColor={'errorColor'}>{t('notifications.errorText')}</Text>}
        </Flex>
      </Skeleton>
    </Flex>
  );
};

const NotificationBodyContent = () => {
  const permission = useNotificationPermission();

  if (permission === undefined) {
    return null;
  }

  switch (permission) {
    // NOTE: There appears to be a bug in iOS 17 where the notification permission resets to prompt even if it's granted. The work around is to use the existence of a Subscription to determine if notifications are working
    case 'prompt':
    case 'granted': {
      return <PermissionGrantedOrPrompt />;
    }
    case 'denied': {
      return <PermissionDenied />;
    }
  }
};

export const NotificationsSupported = () => {
  return (
    <Flex flexDir={'column'} gap={4}>
      <NotificationBodyContent />
    </Flex>
  );
};
