import { gql } from 'src/graphql/__generated__/gql';
import type { GetTransactionQuery } from 'src/graphql/__generated__/graphql';

export const GET_TRANSACTION = gql(/* GraphQL */ `
  query GetTransaction($transactionId: String!) {
    transaction(transactionId: $transactionId) {
      id
      payee
      date
      amount
      currencyCode
      account {
        id
        nickname
      }
      linkedTransfer {
        id
        inflowTransaction {
          id
        }
        outflowTransaction {
          id
        }
      }
    }
  }
`);

export const GET_TRANSACTION_PLACEHOLDER: GetTransactionQuery = {
  transaction: {
    id: 'transaction-placeholder-123',
    payee: 'Uber',
    date: new Date(),
    amount: 1000n,
    currencyCode: 'USD',
    account: {
      id: 'account-placeholder-123',
      nickname: 'Credit Card',
    },
  },
};
