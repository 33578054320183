import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { CREATE_USER, type CreateUserInput, type CreateUserPayload } from 'src/graphql/CreateUser';
import { GET_CURRENT_USER, getCurrentUserCallback } from 'src/graphql/GetCurrentUser';
import { LOGIN, type LoginInput, type LoginPayload } from 'src/graphql/Login';
import { handleLogin } from 'src/util/authentication';
import { EmailField } from './fields/EmailField';
import { PasswordField } from './fields/PasswordField';

export function SignUpApp() {
  const { refetch: refetchCurrentUser, loading: currentUserLoading } = useQuery(GET_CURRENT_USER, {
    skip: true,
    onCompleted: getCurrentUserCallback,
  });

  const { t } = useTranslation();
  const [name, setName] = useState('');
  const nameFieldRef = useRef<HTMLInputElement>(null);

  const [email, setEmail] = useState('');
  const emailFieldRef = useRef<HTMLInputElement>(null);

  const [password, setPassword] = useState('');
  const passwordFieldRef = useRef<HTMLInputElement>(null);

  const [termsCheckbox, setTermsCheckbox] = useState(false);
  const termsCheckboxRef = useRef<HTMLInputElement>(null);

  const [hasFormSubmitted, setFormSubmitted] = useState(false);

  const navigate = useNavigate();

  const [loginMutate, { loading: loginLoading }] = useMutation<LoginPayload, LoginInput>(LOGIN, {
    onCompleted: () => {
      refetchCurrentUser().then(() => {
        handleLogin(navigate, '/verify-email');
      });
    },
    onError: (_err) => {
      // NOTE: Toast display is handled by the error link,
      //  but this is needed to avoid unhandled promise rejection errors
    },
  });

  const [createUserMutate, { loading: createUserLoading }] = useMutation<
    CreateUserPayload,
    CreateUserInput
  >(CREATE_USER, {
    onCompleted: (_data) => {
      loginMutate({ variables: { email, password } });
    },
    onError: (_err) => {
      // NOTE: Toast display is handled by the error link,
      //  but this is needed to avoid unhandled promise rejection errors
    },
  });

  const signUp = () => {
    setFormSubmitted(true);
    if (
      [emailFieldRef, nameFieldRef, passwordFieldRef, termsCheckboxRef].every(
        (ref) => ref.current?.validity.valid,
      )
    ) {
      createUserMutate({ variables: { name, email, password } });
    }
  };

  return (
    <Flex
      flexGrow={1}
      align="center"
      justify="center"
      height="100%"
      flexDir={'column'}
      p={{ base: 2, sm: 0 }}
    >
      <Heading size="lg" marginBottom={5}>
        {t('signUp.title')}
      </Heading>
      <Container
        variant={'card'}
        boxShadow="lg"
        padding={{ base: 4, sm: 8 }}
        maxW={'25rem'}
        mb={2}
        display="flex"
        flexDir={'column'}
        alignItems="flex-start"
        gap={3}
      >
        <FormControl
          id="name"
          isInvalid={hasFormSubmitted && !nameFieldRef.current?.validity.valid}
        >
          <FormLabel>{t('signUp.nameLabel')}</FormLabel>
          <Input
            ref={nameFieldRef}
            type="text"
            autoComplete="name"
            required
            enterKeyHint="next"
            value={name}
            onChange={(e) => setName(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && emailFieldRef.current) {
                emailFieldRef.current.focus();
              }
            }}
          />
          <FormErrorMessage>{nameFieldRef.current?.validationMessage}</FormErrorMessage>
        </FormControl>
        <EmailField
          value={email}
          isInvalid={hasFormSubmitted && !emailFieldRef.current?.validity.valid}
          fieldRef={emailFieldRef}
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && passwordFieldRef.current) {
              passwordFieldRef.current.focus();
            }
          }}
        />
        <PasswordField
          autoComplete="new-password"
          value={password}
          isInvalid={hasFormSubmitted && !passwordFieldRef.current?.validity.valid}
          fieldRef={passwordFieldRef}
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && termsCheckboxRef.current) {
              termsCheckboxRef.current.focus();
            }
          }}
        />
        <Checkbox
          ref={termsCheckboxRef}
          isChecked={termsCheckbox}
          onChange={(e) => setTermsCheckbox(e.target.checked)}
          isInvalid={hasFormSubmitted && !termsCheckboxRef.current?.validity.valid}
          isRequired
        >
          <Trans
            i18nKey="signUp.agreeTosPp"
            components={[
              <Link
                href={`${import.meta.env.VITE_HOMEPAGE}/terms-of-service`}
                colorScheme={'blue'}
              />,
              <Link
                href={`${import.meta.env.VITE_HOMEPAGE}/privacy-policy`}
                colorScheme={'blue'}
              />,
            ]}
          />
        </Checkbox>
        <Button
          type="submit"
          isLoading={createUserLoading || loginLoading || currentUserLoading}
          onClick={signUp}
          alignSelf="flex-end"
          size="md"
          mt={4}
        >
          {t('signUp.actionButtonText')}
        </Button>
      </Container>
      <Link as={RouterLink} to="/login" colorScheme={'blue'} fontWeight="semibold" fontSize={'sm'}>
        {t('signUp.linkToLoginText')}
      </Link>
    </Flex>
  );
}
