import { Container, Flex, Heading, Skeleton, Spacer } from '@chakra-ui/react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { BudgetRow } from './BudgetRow';
import {
  type GetBudgetData,
  type GetBudgetInput,
  GET_BUDGET,
  GET_BUDGET_PLACEHOLDER,
} from 'src/graphql/GetBudget';
import {
  type GetCategoryGroupsWithSpendData,
  type GetCategoryGroupsWithSpendInput,
  GET_CATEGORY_GROUPS_WITH_SPEND,
  GET_CATEGORY_GROUPS_WITH_SPEND_PLACEHOLDER,
} from 'src/graphql/GetCategoryGroupsWithSpend';
import { currentMonth } from 'src/cache';
import { ErrorWithRefetch } from 'src/components/shared/ErrorWithRefetch';
import { EmptyListCta } from 'src/components/shared/EmptyListCta';
import BudgetIcon from 'src/assets/icons/icons8-fund-accounting.svg?react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { utc } from 'src/util/dayjs';
import { generateTransactionsFilteredLink } from 'src/util/transactions';

export function BudgetList() {
  const { t } = useTranslation();
  const currentMonthValue = useReactiveVar(currentMonth);
  const navigate = useNavigate();

  const startDate = utc(currentMonthValue).toDate();
  const endDate = utc(currentMonthValue).endOf('month').toDate();

  const { data, error: categoryGroupError } = useQuery<
    GetCategoryGroupsWithSpendData,
    GetCategoryGroupsWithSpendInput
  >(GET_CATEGORY_GROUPS_WITH_SPEND, {
    variables: {
      startDate: startDate,
      endDate: endDate,
    },
    fetchPolicy: 'cache-and-network',
  });
  const { data: budgetData, error: budgetError } = useQuery<GetBudgetData, GetBudgetInput>(
    GET_BUDGET,
    {
      variables: {
        month: startDate,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const isLoaded = !(data === undefined);
  const categoryGroups =
    isLoaded && data
      ? data?.categoryGroups
      : GET_CATEGORY_GROUPS_WITH_SPEND_PLACEHOLDER.categoryGroups;
  const budget = isLoaded && budgetData ? budgetData.budget : GET_BUDGET_PLACEHOLDER.budget;

  const categoryGroupRows = categoryGroups.map((group) => (
    <Flex key={group.id} flexDirection="column" gap={'px'}>
      <Skeleton isLoaded={isLoaded} minWidth={'16ch'}>
        <Heading size="xs" mb={2} color="subtleText" noOfLines={1}>
          {group.name.toLocaleUpperCase()}
        </Heading>
      </Skeleton>
      <Flex gap={4} flexDirection="column">
        {group.categories.map((category) => {
          const cb = budget?.categoryBudgets.find((subCb) => subCb.category.id === category.id);
          return (
            <BudgetRow
              key={`${category.id}_${cb?.id ?? 'none'}}`}
              budgetId={budget.id}
              category={category}
              categoryBudgetId={cb?.id}
              budgeted={cb?.budgeted}
              isLoaded={isLoaded}
              transactionsFilteredLink={generateTransactionsFilteredLink({
                categoryId: category.id,
                beforeDate: endDate,
                afterDate: startDate,
              })}
            />
          );
        })}
      </Flex>
    </Flex>
  ));

  if (categoryGroupRows.length === 0) {
    return (
      <Container
        display="flex"
        variant="card"
        boxShadow={'base'}
        flexGrow={1}
        maxWidth="100%"
        p={8}
        flexDir={'column'}
      >
        <Spacer flexBasis={'30%'} />
        <EmptyListCta
          title={t('budget.emptyListCta.title')}
          description={t('budget.emptyListCta.description')}
          Icon={BudgetIcon}
          buttonText={t('budget.emptyListCta.buttonText')}
          onClick={() => {
            navigate('/categories');
          }}
        />
        <Spacer flexBasis={'70%'} />
      </Container>
    );
  }

  return (
    <Container
      boxShadow={'base'}
      flexGrow={1}
      maxWidth="100%"
      p={{ base: 4, md: 8 }}
      variant="card"
    >
      {budgetError || categoryGroupError ? (
        <ErrorWithRefetch message={t('budget.errorMessageText')} />
      ) : (
        <Flex gap={8} flexDirection="column">
          {categoryGroupRows}
        </Flex>
      )}
    </Container>
  );
}
