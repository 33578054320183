import { gql } from '@apollo/client';

export const EDIT_CATEGORY_GROUP = gql`
  mutation EditCategoryGroup($id: String!, $name: String!) {
    editCategoryGroup(id: $id, name: $name) {
      id
      name
    }
  }
`;

export interface EditCategoryGroupInput {
  id: string;
  name: string;
}

export interface EditCategoryGroupData {
  __typename: 'Mutation';
  editCategory: {
    id: string;
    name: string;
    __typename: 'CategoryGroup';
  };
}
