import { gql } from '@apollo/client';

export const FRAGMENT_CATEGORY_BUDGETS = gql`
  fragment CategoryBudgets on Budget {
    categoryBudgets {
      id
      budgeted
      category {
        id
      }
    }
  }
`;

export interface FragmentCategoryBudgetsData {
  categoryBudgets: {
    id: string;
    budgeted: number;
    category: {
      id: string;
    };
  }[];
}

export const GET_BUDGET = gql`
  query Budget($month: DateTime!) {
    budget(month: $month) {
      id
      month
      ...CategoryBudgets
    }
  }
  ${FRAGMENT_CATEGORY_BUDGETS}
`;

export interface GetBudgetData {
  budget: {
    id: string;
    month: Date;
  } & FragmentCategoryBudgetsData;
}

export interface GetBudgetInput {
  month: Date;
}

export const GET_BUDGET_PLACEHOLDER: GetBudgetData = {
  budget: {
    id: '123',
    month: new Date(),
    categoryBudgets: [
      {
        id: '789',
        budgeted: 25000,
        category: {
          id: '456',
        },
      },
    ],
  },
};
