import { gql } from '@apollo/client';

export const FRAGMENT_CATEGORY_GROUP = gql`
  fragment CategoryGroupFields on CategoryGroup {
    id
    name
    categories {
      id
      name
      color
    }
  }
`;

export type FragmentCategoryGroupData = {
  id: string;
  name: string;
  categories: {
    id: string;
    name: string;
    color: string;
  }[];
};

export const GET_CATEGORY_GROUPS = gql`
  query CategoryGroups {
    categoryGroups {
      ...CategoryGroupFields
    }
  }
  ${FRAGMENT_CATEGORY_GROUP}
`;

export interface GetCategoryGroupsData {
  categoryGroups: FragmentCategoryGroupData[];
}

export const GET_CATEGORY_GROUPS_PLACEHOLDER: GetCategoryGroupsData = {
  categoryGroups: [
    {
      id: 'group-123',
      name: 'Food & Drink',
      categories: [
        {
          id: 'category-123',
          name: 'Dining Out',
          color: 'orange',
        },
        {
          id: 'category-456',
          name: 'Groceries Out',
          color: 'orange',
        },
      ],
    },
    {
      id: 'group-456',
      name: 'Transportation',
      categories: [
        {
          id: 'category-789',
          name: 'Transportation',
          color: 'blue',
        },
        {
          id: 'category-234',
          name: 'Travel',
          color: 'blue',
        },
      ],
    },
    {
      id: 'group-789',
      name: 'Personal',
      categories: [
        {
          id: 'category-245',
          name: 'Entertainment',
          color: 'red',
        },
        {
          id: 'category-256',
          name: 'Furniture / Decorations',
          color: 'red',
        },
      ],
    },
  ],
};
