import { DeleteIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Input,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { parse } from 'papaparse';
import { useRef, useState } from 'react';
import { AiOutlineFile } from 'react-icons/ai';
import { DividerWithText } from 'src/components/shared/DividerWithText';
import CsvIcon from 'src/assets/icons/icons8-import-csv.svg';

export type CsvFile = {
  fileName: string;
  fileHash: string;
  headers: string[];
  data: Record<string, string>[];
};

interface FileSelectProps {
  csvFiles: CsvFile[] | undefined;
  setCsvFiles: (file: CsvFile[] | undefined) => void;
}

export const FileSelect = ({ csvFiles, setCsvFiles }: FileSelectProps) => {
  const onChange = ({ target: { validity, files } }: React.ChangeEvent<HTMLInputElement>) => {
    if (validity.valid && files) {
      setFiles(files, setCsvFiles);
    }
  };

  return csvFiles && csvFiles.length > 0 ? (
    <SelectedFile files={csvFiles} setCsvFiles={setCsvFiles} />
  ) : (
    <FileInput onChange={onChange} setCsvFiles={setCsvFiles} />
  );
};

const SelectedFile = ({
  files,
  setCsvFiles,
}: {
  files: CsvFile[];
  setCsvFiles: FileSelectProps['setCsvFiles'];
}) => {
  const bg = useColorModeValue('gray.100', 'whiteAlpha.200');
  const multiFileMessage = `${files.length} CSV files selected for import`;

  return (
    <>
      <HStack bg={bg} borderRadius={'lg'} padding={3} justifyContent={'space-between'}>
        <Flex alignItems={'center'} flex={1} gap={2} overflow="hidden">
          <AiOutlineFile size={20} />
          <Text noOfLines={1} maxW="full-content" fontWeight={'semibold'}>
            {files.length > 1 ? multiFileMessage : files[0].fileName}
          </Text>
        </Flex>
        <IconButton
          colorScheme={'gray'}
          icon={<DeleteIcon w={5} h={5} />}
          aria-label="Clear"
          size={'sm'}
          onClick={() => {
            setCsvFiles(undefined);
          }}
        />
      </HStack>
    </>
  );
};

const FileInput = ({
  onChange,
  setCsvFiles,
}: {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  setCsvFiles: FileSelectProps['setCsvFiles'];
}) => {
  const fileUploadInput = useRef<HTMLInputElement>(null);
  const [dragEnterCount, setDragEnterCount] = useState<number>(0);
  const dashedLineColor = useColorModeValue('gray.300', 'gray.600');

  return (
    <Flex
      border={'dashed'}
      borderColor={dashedLineColor}
      borderWidth={2}
      borderRadius={15}
      alignItems={'center'}
      flexDir="column"
      gap={4}
      p={5}
      onDrop={(e) => {
        e.stopPropagation();
        e.preventDefault();

        setFiles(e.dataTransfer.files, setCsvFiles);
        setDragEnterCount(0);
      }}
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDragEnter={(e) => {
        e.stopPropagation();
        setDragEnterCount(dragEnterCount + 1);
      }}
      onDragLeave={(e) => {
        e.stopPropagation();
        setDragEnterCount(Math.max(dragEnterCount - 1, 0));
      }}
      bg={dragEnterCount > 0 ? 'hoverBg' : undefined}
    >
      <Flex alignItems={'center'} flexDir="column" gap={4} pointerEvents={'none'}>
        <img src={CsvIcon} height={80} width={80} alt={'CSV Icon'} />
        <Heading size="md">Drag & Drop CSV Files</Heading>
      </Flex>

      <DividerWithText maxW={120} pointerEvents={'none'}>
        or
      </DividerWithText>

      <Button
        onClick={() => fileUploadInput.current?.click()}
        aria-label="Select Files"
        width={200}
      >
        Select files
        <Input
          ref={fileUploadInput}
          type="file"
          onChange={onChange}
          accept=".csv"
          hidden
          multiple
        />
      </Button>
    </Flex>
  );
};

export const strToSha256 = async (content: string) => {
  const encoder = new TextEncoder();
  const hashBuffer = await crypto.subtle.digest('SHA-256', encoder.encode(content));
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

  return hashHex;
};

// TODO: [UX] Display an error/ warning toast when we need to return undefined
// TODO: [UX] Filter out any CSV file that doesn't match the headers of the first file
const setFiles = (files: FileList, setCsvFiles: FileSelectProps['setCsvFiles']) => {
  const parsePromises: Promise<CsvFile | undefined>[] = [];

  for (let i = 0; i < files.length; i++) {
    const f = files.item(i);
    if (f) {
      const p = new Promise<CsvFile | undefined>((resolve, reject) => {
        if (f.type !== 'text/csv') {
          return resolve(undefined);
        }

        parse<CsvFile['data'][0]>(f, {
          header: true,
          skipEmptyLines: true,
          complete: (results) => {
            if (results.meta.fields === undefined) {
              // No headers
              return resolve(undefined);
            } else if (results.data.length === 0) {
              // Empty file
              return resolve(undefined);
            } else {
              const headers = results.meta.fields;

              return f.text().then(async (content) => {
                const hash = await strToSha256(content);

                return resolve({
                  fileName: f.name,
                  fileHash: hash,
                  headers,
                  data: results.data,
                });
              });
            }
          },
          error: reject,
        });
      });
      parsePromises.push(p);
    }
  }

  return Promise.all(parsePromises).then((cfs) => {
    const nonEmptyFiles = cfs.filter((c): c is CsvFile => c !== undefined);
    setCsvFiles(files.length > 0 ? nonEmptyFiles : undefined);
  });
};
