import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import {
  Avatar,
  Box,
  Container,
  Flex,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Skeleton,
  SkeletonCircle,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';

import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { sidebarExpanded } from 'src/cache';
import { LinkItem } from 'src/components/shared/LinkItem';
import { LOGOUT, type LogoutPayload } from 'src/graphql/Logout';
import { handleLogout } from 'src/util/authentication';

import { ChevronLeftIcon } from '@chakra-ui/icons';
import { type FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import AutomationIcon from 'src/assets/icons/navbar/icons8-bot.svg?react';
import TransactionsIcon from 'src/assets/icons/navbar/icons8-cheque.svg?react';
import InsightsIcon from 'src/assets/icons/navbar/icons8-combo-chart.svg?react';
import DiscordIcon from 'src/assets/icons/navbar/icons8-discord.svg?react';
import BudgetIcon from 'src/assets/icons/navbar/icons8-fund-accounting.svg?react';
import EmailIcon from 'src/assets/icons/navbar/icons8-mail.svg?react';
import MenuIcon from 'src/assets/icons/navbar/icons8-menu-rounded.svg?react';
import OverviewIcon from 'src/assets/icons/navbar/icons8-merchant-account.svg?react';
import SettingsIcon from 'src/assets/icons/navbar/icons8-settings.svg?react';
import CategoriesIcon from 'src/assets/icons/navbar/icons8-tags.svg?react';
import NotificationIcon from 'src/assets/icons/navbar/icons8-mail.svg?react';
import { GET_CURRENT_USER } from 'src/graphql/GetCurrentUser';

interface Links {
  name: string;
  to: string;
  icon: FunctionComponent;
}

export const AppNav = () => {
  const { t } = useTranslation();
  const expandedValue = useReactiveVar(sidebarExpanded);
  const navigate = useNavigate();
  const { data: currentUserData, loading } = useQuery(GET_CURRENT_USER);
  const location = useLocation();

  const [logoutUser] = useMutation<LogoutPayload>(LOGOUT, {
    onCompleted: (data) => {
      if (data.logout.success) {
        handleLogout({ navigate });
      } else {
        // TODO: Display a toast
        alert('Failed to logout');
      }
    },
    onError: (err) => {
      // TODO: Display a toast
      alert(err);
    },
  });

  const desktopWidth = expandedValue ? '10rem' : '3.25rem';
  const mobileWidth = expandedValue ? '10rem' : '0rem';
  const userName = currentUserData?.currentUser.name ?? '';

  const onNavClick = (expanded: boolean) => {
    sidebarExpanded(expanded);
    localStorage.setItem('sidebarExpanded', expanded.toString());
  };

  const onBreakpointNavClick: (() => void) | undefined = useBreakpointValue(
    {
      base: () => {
        onNavClick(false);
      },
      md: undefined,
    },
    { ssr: false },
  );

  const mainLinks: Links[] = [
    { name: t('navigation.links.overviewText'), to: '/overview', icon: OverviewIcon },
    { name: t('navigation.links.transactionsText'), to: '/transactions', icon: TransactionsIcon },
    { name: t('navigation.links.budgetText'), to: '/budget', icon: BudgetIcon },
    { name: t('navigation.links.insightsText'), to: '/insights', icon: InsightsIcon },
    { name: t('navigation.links.automationsText'), to: '/automation', icon: AutomationIcon },
    { name: t('navigation.links.categoriesText'), to: '/categories', icon: CategoriesIcon },
    { name: t('navigation.links.settingsText'), to: '/settings', icon: SettingsIcon },
  ];

  const settingsLinks: Links[] = [
    { name: 'Account', to: '/settings/account', icon: OverviewIcon },
    { name: 'Billing', to: '/settings/billing', icon: TransactionsIcon },
    // TODO: Get an icon in the same style
    { name: 'Notifications', to: '/settings/notifications', icon: NotificationIcon },
  ];

  const links = useMemo(() => {
    if (location.pathname.startsWith('/settings')) {
      return settingsLinks;
    } else {
      return mainLinks;
    }
  }, [location.pathname, settingsLinks, mainLinks]);

  const actionButton = useMemo(() => {
    if (location.pathname.startsWith('/settings')) {
      return (
        <LinkItem
          key={'settings-top'}
          name={'Settings'}
          to={'/'}
          currentPath={location.pathname}
          LinkIcon={ChevronLeftIcon}
          expanded={expandedValue}
          onClick={onBreakpointNavClick}
          fontSize="sm"
        />
      );
    } else {
      return (
        <IconButton
          colorScheme={'gray'}
          icon={<Icon as={MenuIcon} fill={'iconColor'} width={'1.125rem'} height={'1.125rem'} />}
          aria-label={t('navigation.expandButtonLabel')}
          variant="ghost"
          borderRadius={'lg'}
          type="button"
          size={'sm'}
          _hover={{ bg: 'hoverBg' }}
          onClick={() => {
            onNavClick(!expandedValue);
          }}
        />
      );
    }
  }, [location.pathname, expandedValue, onBreakpointNavClick, onNavClick, t]);

  return (
    <Container
      as="nav"
      display={'flex'}
      justifyContent="space-between"
      flexDirection="column"
      width={{ base: mobileWidth, md: desktopWidth }}
      maxWidth={{ base: mobileWidth, md: desktopWidth }}
      overflowY="auto"
      overflowX="hidden"
      borderRadius={'none'}
      flexShrink={0}
      pb={4}
      px={2}
      borderRightColor={'borderColor'}
      borderRightWidth={1}
      boxShadow="none"
      transitionProperty={'width maxWidth'}
      transitionDuration={'200ms'}
      transitionTimingFunction={'ease-in'}
      position={{ base: 'absolute', md: 'inherit' }}
      top={0}
      bottom={0}
      zIndex={{ base: 'overlay', md: 'auto' }}
      visibility={{ base: expandedValue ? 'visible' : 'hidden', md: 'visible' }}
    >
      <Flex justifyContent="space-between" flexGrow={1} flexDirection="column" gap={4}>
        <Flex alignItems={'flex-start'} flexGrow={1} flexDirection="column" gap={1} mt={2} mb={10}>
          {actionButton}
          {links.map((link) => (
            <LinkItem
              key={link.name}
              name={link.name}
              to={link.to}
              currentPath={location.pathname}
              LinkIcon={link.icon}
              expanded={expandedValue}
              onClick={onBreakpointNavClick}
            />
          ))}
        </Flex>
        <Menu>
          <MenuButton>
            <Flex alignItems="center" justifyContent={'flex-start'} gap={2} ml={0.5}>
              <SkeletonCircle isLoaded={!loading}>
                <Avatar name={userName} size="xs" bg={'subtleBg'} width={'2rem'} height={'2rem'} />
              </SkeletonCircle>

              <Skeleton isLoaded={!loading} flex={1} alignItems="center">
                <Text
                  isTruncated
                  fontWeight="semibold"
                  maxW="12ch"
                  textAlign={'start'}
                  transitionProperty={'opacity'}
                  transitionDuration={'200ms'}
                  visibility={{
                    base: 'visible',
                    md: expandedValue && !loading ? 'visible' : 'hidden',
                  }}
                  opacity={{ base: 1, md: expandedValue ? 1 : 0 }}
                >
                  {userName}
                </Text>
              </Skeleton>
            </Flex>
          </MenuButton>
          <Portal>
            <MenuList zIndex={'tooltip'}>
              <Box ml={3} mt={1}>
                <Text fontSize="sm">{t('navigation.menu.signedInAs')}</Text>
                <Text fontWeight="semibold">{userName}</Text>
              </Box>
              <MenuDivider />
              <Link
                as={RouterLink}
                to="/settings"
                _hover={{ textDecoration: 'none' }}
                onClick={onBreakpointNavClick}
              >
                <MenuItem gap={2} alignItems="center" display={'flex'}>
                  <Icon
                    as={SettingsIcon}
                    fill={'iconColor'}
                    width={'1.125rem'}
                    height={'1.125rem'}
                  />
                  <Text>{t('navigation.menu.settingsButtonText')}</Text>
                </MenuItem>
              </Link>
              <MenuDivider />
              <Link
                href={import.meta.env.VITE_DISCORD_INVITE_LINK}
                _hover={{ textDecoration: 'none' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <MenuItem gap={2} alignItems="center" display={'flex'}>
                  <Icon
                    as={DiscordIcon}
                    fill={'iconColor'}
                    width={'1.125rem'}
                    height={'1.125rem'}
                  />
                  <Text>{t('navigation.menu.discordButtonText')}</Text>
                </MenuItem>
              </Link>
              <Link
                href={`mailto:${import.meta.env.VITE_SUPPORT_EMAIL}`}
                _hover={{ textDecoration: 'none' }}
              >
                <MenuItem gap={2} alignItems="center" display={'flex'}>
                  <Icon as={EmailIcon} fill={'iconColor'} width={'1.125rem'} height={'1.125rem'} />
                  <Text>{t('navigation.menu.supportButtonText')}</Text>
                </MenuItem>
              </Link>
              <MenuDivider />
              <MenuItem onClick={() => logoutUser()}>
                <Text>{t('navigation.menu.logOutButtonText')}</Text>
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </Flex>
    </Container>
  );
};
