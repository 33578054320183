import { gql } from '@apollo/client';

export const GET_CATEGORY_GROUPS_WITH_SPEND = gql`
  query CategoryGroupsWithSpend($startDate: DateTime!, $endDate: DateTime!) {
    categoryGroups {
      id
      name
      categories {
        id
        name
        spend(startDate: $startDate, endDate: $endDate)
        color
      }
    }
  }
`;

export interface GetCategoryGroupsWithSpendInput {
  startDate: Date;
  endDate: Date;
}

export interface GetCategoryGroupsWithSpendData {
  categoryGroups: {
    id: string;
    name: string;
    categories: {
      id: string;
      name: string;
      spend: number;
      color: string;
    }[];
  }[];
}

export const GET_CATEGORY_GROUPS_WITH_SPEND_PLACEHOLDER: GetCategoryGroupsWithSpendData = {
  categoryGroups: [
    {
      id: '123',
      name: 'Food & Drink',
      categories: [
        {
          id: '456',
          name: 'Dining Out',
          spend: -100,
          color: 'orange',
        },
        {
          id: '789',
          name: 'Groceries Out',
          spend: -100,
          color: 'orange',
        },
      ],
    },
    {
      id: '321',
      name: 'Transportation',
      categories: [
        {
          id: '654',
          name: 'Transportation',
          spend: -100,
          color: 'blue',
        },
        {
          id: '987',
          name: 'Travel',
          spend: -100,
          color: 'blue',
        },
      ],
    },
  ],
};
