import dayjs, { extend as dayjsExtend, unix as dayjsUnix } from 'dayjs';

import utcPlugin from 'dayjs/plugin/utc';
dayjsExtend(utcPlugin);
export const utc = dayjs.utc;

import durationPlugin from 'dayjs/plugin/duration';
import relativeTimePlugin from 'dayjs/plugin/relativeTime';
dayjsExtend(durationPlugin);
dayjsExtend(relativeTimePlugin);
export const duration = dayjs.duration;

import isSameOrBeforePlugin from 'dayjs/plugin/isSameOrBefore';
dayjsExtend(isSameOrBeforePlugin);

import minMaxPlugin from 'dayjs/plugin/minMax';
dayjsExtend(minMaxPlugin);
export const min = dayjs.min;

export const unix = dayjsUnix;

export const now = dayjs;
export { dayjs };
