import { useLazyQuery } from '@apollo/client';
import { AddIcon, CloseIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { GetTransactionsQuery } from 'src/graphql/__generated__/graphql';
import { formatCurrency } from 'src/util';
import {
  GET_SPLIT_USER,
  type GetSplitUserData,
  type GetSplitUserInput,
} from './GetSplitUser.graphql';

const UserTable = ({
  venmoUsers,
  setVenmoUsers,
  typeLabel,
  amountOwed,
}: {
  venmoUsers: VenmoUser[];
  setVenmoUsers: (u: VenmoUser[]) => void;
  typeLabel: string;
  amountOwed: number;
}) => {
  const { t } = useTranslation();

  return (
    <TableContainer width={'full'}>
      <Table variant="default" size="sm" hidden={venmoUsers.length === 0}>
        <Thead>
          <Tr alignItems={'flex-end'}>
            <Th>{t('transactions.editModal.splitTab.table.userHeader')}</Th>
            <Th>{typeLabel}</Th>
            <Th width="2rem" />
          </Tr>
        </Thead>
        <Tbody>
          {venmoUsers.map((vu) => (
            <Tr key={vu.username} alignItems={'center'}>
              <Td>
                <Flex gap={2} align="center">
                  <Avatar name={vu.name} src={vu.imageUrl ?? undefined} size="sm" />
                  <Flex flexDirection={'column'} gap={1}>
                    <Text fontSize="md">{`${vu.name}`}</Text>
                    <Link href={`https://account.venmo.com/u/${vu.username}`} target="_blank">
                      <Text color="gray.500">{`@${vu.username}`}</Text>
                    </Link>
                  </Flex>
                </Flex>
              </Td>
              <Td>
                <Text fontSize="md">
                  {formatCurrency({ cents: amountOwed, currencyCode: 'USD' })}
                </Text>
              </Td>
              <Td width="2rem">
                <IconButton
                  size="xs"
                  colorScheme="gray"
                  variant="ghost"
                  aria-label="Remove Username"
                  icon={<CloseIcon />}
                  onClick={() => {
                    setVenmoUsers(venmoUsers.filter((u) => u !== vu));
                  }}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

/**
 * Generate a Venmo Payment Link for the given transaction that deep links into the Venmo App/ Website to make it easy to split transactions
 *
 * @returns A Venmo Payment Link
 * @see https://venmo.com/paymentlinks
 */
const generateVenmoUrl = ({
  transactionType,
  amountCents,
  note,
  recipients,
}: {
  transactionType: 'pay' | 'charge';
  amountCents: number;
  note: string;
  recipients: string[];
}): URL => {
  // TODO: Switch to https://account.venmo.com/pay and venmo://paycharge to provide a better experience. Need to reliable determine if on mobile w/ app installed vs web
  const baseUrl = 'https://venmo.com/';

  const url = new URL(baseUrl);
  url.searchParams.append('txn', transactionType);
  url.searchParams.append('audience', 'private');
  url.searchParams.append('amount', (Math.abs(amountCents) / 100.0).toFixed(2).toString());
  url.searchParams.append('note', note);
  url.searchParams.append('recipients', recipients.join(','));

  return url;
};

interface VenmoUser {
  name: string;
  username: string;
  imageUrl: string | null;
}

interface EditTransactionModalProps {
  isOpen: boolean;
  onClose: () => void;
  transaction: GetTransactionsQuery['transactions']['transactions'][0];
}

export const SplitPanel = ({
  transaction,
  onClose,
}: {
  transaction: EditTransactionModalProps['transaction'];
  onClose: EditTransactionModalProps['onClose'];
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [username, setUsername] = useState<string>('');
  const [venmoUsers, setVenmoUsers] = useState<VenmoUser[]>([]);
  const amountOwed = Math.abs(Number(transaction.amount) / (venmoUsers.length + 1));
  const totalOwed = amountOwed * venmoUsers.length;
  const transactionType = Math.sign(Number(transaction.amount)) === 1 ? 'pay' : 'charge';
  const typeLabel = t(`transactions.editModal.splitTab.${transactionType}`);

  const [lookupUsername, { loading }] = useLazyQuery<GetSplitUserData, GetSplitUserInput>(
    GET_SPLIT_USER,
  );

  const addVenmoUsername = () => {
    lookupUsername({ variables: { username } }).then(({ data }) => {
      const splitUser = data?.getSplitUser;
      if (splitUser === null || splitUser === undefined) {
        toast({
          status: 'warning',
          title: 'User not found',
          description: `No Venmo user found for "${username}"`,
        });
        return;
      }

      setVenmoUsers([
        ...venmoUsers,
        {
          name: splitUser.name,
          username: splitUser.username,
          imageUrl: splitUser.imageUrl,
        },
      ]);
      setUsername('');
    });
  };

  const url = useMemo(
    () =>
      generateVenmoUrl({
        transactionType,
        amountCents: amountOwed,
        note: `${transaction.payee}${transaction.notes ? ` - ${transaction.notes}` : ''}`,
        recipients: venmoUsers.map((vu) => vu.username),
      }),
    [venmoUsers, amountOwed, transactionType, transaction.payee, transaction.notes],
  );

  return (
    <Flex direction="column" alignItems="center" gap={4}>
      <FormControl id="usernameInput">
        <FormLabel>{t('transactions.editModal.splitTab.input.venmoUsernameLabel')}</FormLabel>
        <Flex gap={2}>
          <Input
            id="merchant"
            type="text"
            required
            enterKeyHint="enter"
            value={username}
            autoCapitalize="off"
            autoComplete="off"
            autoCorrect="off"
            placeholder={t('transactions.editModal.splitTab.input.placeholder')}
            onChange={(e) => setUsername(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && username.length > 0 && !loading) {
                addVenmoUsername();
              }
            }}
          />
          <IconButton
            colorScheme={'gray'}
            isDisabled={username.length === 0 || loading}
            onClick={() => {
              addVenmoUsername();
            }}
            icon={<AddIcon />}
            aria-label={t('transactions.editModal.splitTab.input.addButtonLabel')}
            isLoading={loading}
          />
        </Flex>
        <FormHelperText>
          {t('transactions.editModal.splitTab.input.venmoUsernameHelperText')}
        </FormHelperText>
      </FormControl>
      <UserTable
        venmoUsers={venmoUsers}
        typeLabel={typeLabel}
        setVenmoUsers={setVenmoUsers}
        amountOwed={amountOwed}
      />
      <Flex alignSelf={'flex-end'} gap={2}>
        <Button colorScheme="gray" onClick={onClose} ml={3}>
          {t('common.modal.cancelButtonText')}
        </Button>
        <Link
          href={venmoUsers.length > 0 ? url.toString() : undefined}
          _hover={{ textDecoration: 'none' }}
          isExternal
        >
          <Button
            colorScheme="blue"
            rightIcon={<ExternalLinkIcon />}
            isDisabled={venmoUsers.length === 0}
          >
            {t('transactions.editModal.splitTab.actionButtonText', {
              total: formatCurrency({ cents: totalOwed, currencyCode: 'USD' }),
              type: typeLabel,
            })}
          </Button>
        </Link>
      </Flex>
    </Flex>
  );
};
