import { gql } from '@apollo/client';

export const GET_ITEM = gql`
  query GetItem($itemId: String!) {
    item(itemId: $itemId) {
      id
      lastSyncedAt
    }
  }
`;

export interface GetItemInput {
  itemId: string;
}

export interface GetItemData {
  item: {
    id: string;
    lastSyncedAt: Date | null;
  };
}
