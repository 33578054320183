import { useMutation } from '@apollo/client';
import { Flex, Spinner, useToast } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import {
  type PlaidLinkOnExit,
  type PlaidLinkOnSuccessMetadata,
  type PlaidLinkOptions,
  usePlaidLink,
} from 'react-plaid-link';
import { useNavigate } from 'react-router-dom';
import { plaidItemConnection } from 'src/cache';
import {
  type ExchangePublicTokenData,
  type ExchangePublicTokenInput,
  EXCHANGE_PUBLIC_TOKEN,
} from 'src/graphql/ExchangePublicToken';

export const PLAID_LINK_TOKEN_KEY = 'plaidLinkToken';

/**
 * Re-open the Plaid Link after a redirect back from the user's bank as part of the OAuth flow.
 * The link token is persisted in local storage under PLAID_LINK_TOKEN_KEY during the first part of the Plaid Link flow either as part of new account creation or account updating
 *
 * @see https://plaid.com/docs/link/oauth/
 */
export const PlaidOauthRedirect = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [exchangePublicToken] = useMutation<ExchangePublicTokenData, ExchangePublicTokenInput>(
    EXCHANGE_PUBLIC_TOKEN,
  );

  const onSuccess = useCallback(
    (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
      exchangePublicToken({
        variables: {
          publicToken,
        },
      }).then(({ data }) => {
        if (data === null || data === undefined) {
          toast({
            title: 'There was an issue connecting to your bank',
            description:
              'Please try creating the connection again. If the issue persists, reach out to support.',
            status: 'error',
            duration: 5_000,
          });
          return;
        }

        const itemId = data.exchangePublicToken.itemId;
        plaidItemConnection({
          itemId,
          institutionName: metadata.institution?.name ?? 'your bank',
          status: 'importing',
        });
      });

      localStorage.removeItem(PLAID_LINK_TOKEN_KEY);
      navigate('/overview');
    },
    [exchangePublicToken, navigate, toast],
  );

  const onExit = useCallback<PlaidLinkOnExit>(
    (_error: Parameters<PlaidLinkOnExit>[0]) => {
      localStorage.removeItem(PLAID_LINK_TOKEN_KEY);
      navigate('/overview');
    },
    [navigate],
  );

  const config: PlaidLinkOptions = {
    token: localStorage.getItem(PLAID_LINK_TOKEN_KEY),
    receivedRedirectUri: window.location.href,
    onSuccess,
    onExit,
  };

  const { open, ready } = usePlaidLink(config);

  // Open the plaid link after it has loaded or redirect back to the overview page if there is no token
  useEffect(() => {
    if (localStorage.getItem(PLAID_LINK_TOKEN_KEY) !== null) {
      open();
    } else {
      navigate('/overview');
    }
  }, [navigate, open]);

  return (
    <Flex justifyContent="center" alignItems="center" height="100%">
      {!ready ? <Spinner size="xl" /> : null}
    </Flex>
  );
};
