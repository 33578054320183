import { useMutation, type MutationTuple } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import {
  categoryOptimisticResponse,
  type SetCategoryData,
  type SetCategoryInput,
  SET_CATEGORY,
} from 'src/graphql/SetCategory';
import { CategorySelect } from 'src/components/transactions/CategorySelect';
import type { GetTransactionsQuery } from 'src/graphql/__generated__/graphql';
import type { ThemingProps } from '@chakra-ui/react';

type ColumnData = GetTransactionsQuery['transactions']['transactions'][0];

export const CategoryCell = ({
  transaction,
  size = 'xs',
}: { transaction: ColumnData; size?: ThemingProps<'Select'>['size'] }) => {
  const [mutate] = useMutation<SetCategoryData, SetCategoryInput>(SET_CATEGORY, {
    onError: (_err) => {
      // NOTE: Toast display is handled by the error link,
      //  but this is needed to avoid unhandled promise rejection errors
    },
  });

  const onChangeCategoryFn = useCallback(
    (transactionId: string, mutateFn: MutationTuple<SetCategoryData, SetCategoryInput>[0]) =>
      (categoryId: string | undefined) => {
        mutateFn({
          variables: {
            transactionId: transactionId,
            categoryId: categoryId === 'uncategorized' ? undefined : categoryId,
          },
          optimisticResponse: categoryOptimisticResponse(transaction.id, categoryId),
        });
      },
    [transaction.id],
  );

  return useMemo(
    () => (
      <CategorySelect
        size={size}
        value={transaction?.category?.id}
        onChange={onChangeCategoryFn(transaction.id, mutate)}
        disabled={transaction.linkedTransfer !== null}
      />
    ),
    [
      transaction?.category?.id,
      transaction.linkedTransfer,
      size,
      onChangeCategoryFn,
      transaction.id,
      mutate,
    ],
  );
};
