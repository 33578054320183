import { Text, Flex, IconButton } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { useReactiveVar } from '@apollo/client';

import { currentMonth } from 'src/cache';

function nextMonth(timestamp: number): number {
  const date = new Date(timestamp);
  return new Date(date.setMonth(date.getMonth() + 1)).getTime();
}

function previousMonth(timestamp: number): number {
  const date = new Date(timestamp);
  return new Date(date.setMonth(date.getMonth() - 1)).getTime();
}

function formatDate(timestamp: number): string {
  return new Date(timestamp)
    .toLocaleString('default', { month: 'short', year: 'numeric' })
    .toUpperCase();
}

function inFuture(timestamp: number): boolean {
  return new Date() < new Date(timestamp);
}

function MonthPicker() {
  const currentMonthValue = useReactiveVar(currentMonth);

  if (currentMonthValue === undefined) return null;

  return (
    <Flex alignItems="center" flexShrink={0} gap={{ base: 1, md: 4 }}>
      <IconButton
        colorScheme={'gray'}
        variant={'outline'}
        size="sm"
        onClick={() => currentMonth(previousMonth(currentMonthValue))}
        icon={<ChevronLeftIcon />}
        fontSize={'xl'}
        isRound={true}
        aria-label="Previous Month"
      />
      <Text
        fontSize={{ base: 'lg', md: '2xl' }}
        fontWeight="semibold"
        width={{ base: '5.5rem', md: 120 }}
        align="center"
      >
        {formatDate(currentMonthValue)}
      </Text>
      <IconButton
        colorScheme={'gray'}
        variant={'outline'}
        size="sm"
        onClick={() => currentMonth(nextMonth(currentMonthValue))}
        isDisabled={inFuture(nextMonth(currentMonthValue))}
        icon={<ChevronRightIcon />}
        fontSize={'xl'}
        isRound={true}
        aria-label="Next Month"
      />
    </Flex>
  );
}

export { MonthPicker };
