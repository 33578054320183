import { gql } from 'src/graphql/__generated__/gql';

export const FIND_OR_CREATE_CATEGORIES_BY_NAME_MUTATION = gql(/* GraphQL */ `
mutation FindOrCreateCategoriesByName($categoryGroupName: String!, $categoryNames: [String!]!) {
  findOrCreateCategoriesByName(categoryGroupName: $categoryGroupName, categoryNames: $categoryNames) {
    id
    name
  }
}
`);
