import { gql } from '@apollo/client';
import type { TriggerField, TriggerFieldMatcher } from '@flume-finance/common';

export const SIMULATE_AUTOMATION = gql`
  query SimulateAutomation($simulateAutomationInput: SimulateAutomationInput!) {
    simulateAutomation(simulateAutomationInput: $simulateAutomationInput) {
      count
    }
  }
`;

export interface SimulateAutomationInput {
  simulateAutomationInput: {
    triggers: [
      {
        field: TriggerField;
        matcher: TriggerFieldMatcher;
        value: string;
      },
    ];
    overrideExistingCategories: boolean;
  };
}

export interface SimulateAutomationData {
  simulateAutomation: {
    count: number;
  };
}
