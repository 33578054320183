import { useQuery, useReactiveVar } from '@apollo/client';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  IconButton,
  Skeleton,
  Spinner,
  Stat,
  StatArrow,
  StatHelpText,
  StatLabel,
  StatNumber,
  Tab,
  TabList,
  Tabs,
  Text,
  useToken,
} from '@chakra-ui/react';
import { theme } from '@flume-finance/ui';
import type { ManipulateType } from 'dayjs';
import { t } from 'i18next';
import { useMemo, useState } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import type { ContentType } from 'recharts/types/component/Tooltip';
import { netWorthObfuscated } from 'src/cache';
import { ErrorWithRefetch } from 'src/components/shared/ErrorWithRefetch';
import { GET_CURRENT_USER } from 'src/graphql/GetCurrentUser';
import { GET_NET_WORTH_HISTORY } from 'src/graphql/GetNetWorthHistory';
import {
  AssetAggregationInterval,
  type GetNetWorthHistoryQueryVariables,
} from 'src/graphql/__generated__/graphql';
import { formatCurrency } from 'src/util';
import { utc } from 'src/util/dayjs';

const CustomTooltip: ContentType<string, string> = ({ payload, label, coordinate }) => {
  const { data: currentUserData } = useQuery(GET_CURRENT_USER);
  const currencyCode = currentUserData?.currentUser.currencyCode ?? 'USD';

  const width = 100;
  const leftOffset = useMemo(() => (coordinate?.x ?? 0) - width / 2, [coordinate?.x]);

  if (!payload || !payload[0]) return null;

  return (
    <>
      <Flex
        position="absolute"
        top={'-20px'}
        left={leftOffset}
        width={width}
        background="mainBg"
        justifyContent="center"
      >
        <Text fontWeight={'bold'} color="indicatorDot" alignSelf={'center'}>
          {formatCurrency({
            cents: Number(payload[0].value),
            currencyCode,
            hideCents: true,
          })}
        </Text>
      </Flex>
      <Flex
        position="absolute"
        bottom={'-165px'}
        left={leftOffset}
        width={width}
        background="mainBg"
        justifyContent="center"
        alignItems={'flex-start'}
      >
        <Text fontWeight={'bold'} color="indicatorDot">
          {utc(label).format('M/D/YYYY')}
        </Text>
      </Flex>
    </>
  );
};

export const NetWorthChart = () => {
  const netWorthObfuscatedValue = useReactiveVar(netWorthObfuscated);
  const [blue, borderColor, subtleText] = useToken('colors', [
    'indicatorDot',
    'borderColor',
    'subtleText',
  ]);
  const [tabIdx, setTabIdx] = useState(1);
  const idxToInterval: {
    value: number;
    unit: ManipulateType;
    interval: GetNetWorthHistoryQueryVariables['interval'];
    xLabels?: string[];
  }[] = [
    {
      value: 1,
      unit: 'month',
      interval: AssetAggregationInterval.Daily,
    },
    {
      value: 3,
      unit: 'month',
      interval: AssetAggregationInterval.Weekly,
    },
    { value: 1, unit: 'year', interval: AssetAggregationInterval.Monthly },
    { value: 5, unit: 'year', interval: AssetAggregationInterval.Quarterly },
  ];

  const { data: currentUserData } = useQuery(GET_CURRENT_USER);
  const currencyCode = currentUserData?.currentUser.currencyCode ?? 'USD';

  const { loading, data, error, refetch } = useQuery(GET_NET_WORTH_HISTORY, {
    variables: {
      startDate: utc()
        .subtract(idxToInterval[tabIdx].value, idxToInterval[tabIdx].unit)
        .startOf(idxToInterval[tabIdx].unit === 'year' ? 'year' : 'month')
        .toDate(),
      // NOTE: If you use utc() directly, the component will re-render every millisecond
      endDate: utc().add(1, 'day').startOf('day').toDate(),
      interval: idxToInterval[tabIdx].interval,
    },
  });

  const minValue = useMemo(() => {
    return Math.min(...(data?.netWorthHistory.history.map((h) => Number(h.value)) ?? [])) * 0.99;
  }, [data?.netWorthHistory.history]);

  const maxValue = useMemo(() => {
    return Math.max(...(data?.netWorthHistory.history.map((h) => Number(h.value)) ?? [])) * 1.01;
  }, [data?.netWorthHistory.history]);

  if (error) {
    return <ErrorWithRefetch refetch={refetch} message={'Failed to load Net Worth'} />;
  }

  return (
    <Flex
      borderWidth={1}
      borderColor={'borderColor'}
      borderStyle={'solid'}
      p={2}
      borderRadius="lg"
      height={275}
      flexDirection="column"
      position={'relative'}
    >
      <Flex justifyContent={'space-between'}>
        <Stat flex={1}>
          <StatLabel>{t('overview.netWorth.netWorthLabel')}</StatLabel>
          <StatNumber as="h2">
            <Skeleton width="fit-content" isLoaded={!loading}>
              <Flex flexDirection={'row'} alignItems="center" gap={1}>
                {netWorthObfuscatedValue ? (
                  <StatNumber>
                    {formatCurrency({
                      cents: Number(data?.netWorthHistory.netWorth) < 0 ? -10_500_00 : 10_500_00,
                      currencyCode,
                      notation: 'compact',
                      hideCents: true,
                    }).replaceAll(/[0-9]/g, '●')}
                  </StatNumber>
                ) : (
                  <StatNumber fontSize="2xl">
                    {formatCurrency({
                      cents: Number(data?.netWorthHistory.netWorth),
                      currencyCode,
                      notation: 'compact',
                      hideCents: true,
                    })}
                  </StatNumber>
                )}
                <IconButton
                  size="sm"
                  variant="ghost"
                  colorScheme={'gray'}
                  aria-label="hide"
                  icon={netWorthObfuscatedValue ? <ViewIcon /> : <ViewOffIcon />}
                  onClick={() => {
                    const newValue = !netWorthObfuscatedValue;
                    netWorthObfuscated(newValue);
                    localStorage.setItem('netWorthObfuscated', newValue.toString());
                  }}
                />
              </Flex>
            </Skeleton>
          </StatNumber>
          <StatHelpText alignItems={'center'}>
            <Skeleton width="fit-content" isLoaded={!loading}>
              <StatArrow
                type={
                  data?.netWorthHistory.percentChange.charAt(0) === '-' ? 'decrease' : 'increase'
                }
              />
              {data?.netWorthHistory.percentChange}
            </Skeleton>
          </StatHelpText>
        </Stat>
        <Flex gap={'px'} justifyContent="center">
          <Tabs
            variant="soft-rounded"
            colorScheme="blue"
            size="sm"
            onChange={(idx) => setTabIdx(idx)}
            index={tabIdx}
          >
            <TabList>
              <Tab>1M</Tab>
              <Tab>3M</Tab>
              <Tab>1Y</Tab>
              <Tab>5Y</Tab>
            </TabList>
          </Tabs>
        </Flex>
      </Flex>
      <Box position="relative" minH={175}>
        {loading ? (
          <Flex justifyContent={'center'} alignSelf="center" flex={1}>
            <Spinner size="lg" />
          </Flex>
        ) : (
          <Box position="absolute" left={0} right={0} top={0} bottom={0}>
            <ResponsiveContainer width={'100%'} height={'100%'}>
              <AreaChart
                data={data?.netWorthHistory.history.map((h) => ({
                  date: h.date,
                  value: Number(h.value),
                }))}
              >
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor={blue} stopOpacity={0.6} />
                    <stop offset="95%" stopColor={blue} stopOpacity={0.05} />
                  </linearGradient>
                </defs>
                <CartesianGrid stroke={borderColor} vertical={false} />
                <Area
                  type="monotone"
                  dataKey="value"
                  strokeWidth={2}
                  stroke={blue}
                  fill={'url(#colorUv)'}
                  dot={false}
                />
                <YAxis
                  type="number"
                  domain={[minValue, maxValue]}
                  ticks={[0.25, 0.5, 0.75].map((v) => v * (maxValue - minValue) + minValue)}
                  hide={true}
                />
                <Tooltip
                  cursor={{ stroke: blue }}
                  position={{ x: 0, y: 0 }}
                  content={CustomTooltip}
                />
                <XAxis
                  dataKey={'date'}
                  ticks={data?.netWorthHistory.xLabels ?? []}
                  tickFormatter={(v) => utc(v).format(data?.netWorthHistory.xLabelFormat)}
                  fontSize={theme.fontSizes.sm}
                  fontFamily={theme.fonts.body}
                  fontWeight={'bold'}
                  strokeWidth={0}
                  tick={{ fill: subtleText }}
                />
              </AreaChart>
            </ResponsiveContainer>
          </Box>
        )}
      </Box>
    </Flex>
  );
};
