import { gql } from '@apollo/client';
import { ActionField, TriggerField, TriggerFieldMatcher } from '@flume-finance/common';

export const FRAGMENT_AUTOMATION_FIELDS = gql`
  fragment AutomationFields on Automation {
    id
    triggers {
      id
      field
      matcher
      value
    }
    actions {
      id
      field
      value
    }
  }
`;

export const GET_AUTOMATIONS = gql`
  query GetAutomations {
    automations {
      ...AutomationFields
    }
  }
  ${FRAGMENT_AUTOMATION_FIELDS}
`;

export interface GetAutomationsData {
  automations: {
    id: string;
    triggers: [
      {
        id: string;
        field: TriggerField;
        matcher: TriggerFieldMatcher;
        value: string;
      },
    ];
    actions: [
      {
        id: string;
        field: ActionField;
        value: string;
      },
    ];
  }[];
}

export const GET_AUTOMATIONS_PLACEHOLDER: GetAutomationsData = {
  automations: [
    {
      id: '123',
      triggers: [
        {
          id: '456',
          field: TriggerField.PAYEE,
          matcher: TriggerFieldMatcher.CONTAINS,
          value: 'test',
        },
      ],
      actions: [
        {
          id: '789',
          field: ActionField.CATEGORY,
          value: 'test',
        },
      ],
    },
    {
      id: '456',
      triggers: [
        {
          id: '456',
          field: TriggerField.PAYEE,
          matcher: TriggerFieldMatcher.CONTAINS,
          value: 'test',
        },
      ],
      actions: [
        {
          id: '789',
          field: ActionField.CATEGORY,
          value: 'test',
        },
      ],
    },
    {
      id: '789',
      triggers: [
        {
          id: '456',
          field: TriggerField.PAYEE,
          matcher: TriggerFieldMatcher.CONTAINS,
          value: 'test',
        },
      ],
      actions: [
        {
          id: '789',
          field: ActionField.CATEGORY,
          value: 'test',
        },
      ],
    },
  ],
};
