export const CATEGORY_COLORS: string[] = [
  'blue',
  'gray',
  'green',
  'orange',
  'pink',
  'purple',
  'red',
  'teal',
  'yellow',
];
