import { Button, Flex, HStack, Select, Stack, Text } from '@chakra-ui/react';
import { useEffect, type Dispatch, type SetStateAction } from 'react';
import { formatCurrency, parseAmount } from 'src/util';
import { type CsvFile, FileSelect } from './FileSelect';

type TransactionField = {
  name: 'Merchant' | 'Payee' | 'Amount' | 'Date' | 'Account' | 'Category' | 'Notes' | 'Type';
  type: 'text' | 'date' | 'currency';
};

const FIELD_COLUMN_BASIS = 90;
// TODO: [Tech Debt] Maybe pull this from some type enum from the transaction create mutation
const TRANSACTION_FIELDS: TransactionField[] = [
  { name: 'Merchant', type: 'text' },
  { name: 'Payee', type: 'text' },
  { name: 'Amount', type: 'currency' },
  { name: 'Date', type: 'date' },
  { name: 'Account', type: 'text' },
  { name: 'Category', type: 'text' },
  { name: 'Notes', type: 'text' },
  { name: 'Type', type: 'text' },
];

const ColumnFieldHeader = () => (
  <HStack flexGrow={1}>
    <Text
      flexBasis={FIELD_COLUMN_BASIS}
      fontSize={'xs'}
      casing={'uppercase'}
      fontWeight={'semibold'}
      color="gray.500"
    >
      Field
    </Text>
    <Text fontSize={'xs'} casing={'uppercase'} fontWeight={'semibold'} color="gray.500">
      CSV Header
    </Text>
  </HStack>
);

interface FieldPreviewProps {
  csvRow: Record<string, string>;
  csvHeader: string | undefined;
  fieldType: TransactionField['type'];
}

const transformValue = (value: string, fieldType: TransactionField['type']) => {
  switch (fieldType) {
    case 'text': {
      return value;
    }
    case 'date': {
      return new Date(value).toLocaleDateString();
    }
    case 'currency': {
      return formatCurrency({ cents: parseAmount(value), currencyCode: 'USD' });
    }
  }
};

const FieldPreview = ({ csvRow, csvHeader, fieldType }: FieldPreviewProps) => {
  // NOTE: Add a placeholder that is hidden to take up space to avoid shifting after previews appear
  if (csvHeader === undefined) {
    return (
      <Text fontSize="sm" visibility={csvHeader === undefined ? 'hidden' : undefined}>
        placeholder
      </Text>
    );
  }

  const initialValue = csvRow[csvHeader];
  const transformedValue = transformValue(initialValue, fieldType);

  return (
    <Text color="gray.500" noOfLines={1} fontSize={'sm'} alignSelf={'flex-end'} maxW={350}>
      {initialValue}
      {initialValue !== transformedValue && ` → ${transformedValue}`}
    </Text>
  );
};

interface FieldMappingForm {
  csvHeaders: string[];
  csvRow: Record<string, string>;
  fieldMapping: Record<TransactionField['name'], string | undefined>;
  onFieldMappingChange: (field: string, csvHeader: string) => void;
}

const FieldMappingForm = ({
  csvRow,
  csvHeaders,
  onFieldMappingChange,
  fieldMapping,
}: FieldMappingForm) => {
  return (
    <Stack gap={2}>
      <ColumnFieldHeader />
      {TRANSACTION_FIELDS.map(({ name, type }) => (
        <Stack key={name} spacing={0}>
          <Flex alignItems={'center'}>
            <Stack width={FIELD_COLUMN_BASIS} spacing={0}>
              <Text>{name}</Text>
              <Text
                fontSize={'xs'}
                casing="uppercase"
                color="gray.500"
                fontFamily={'mono'}
                fontWeight={'semibold'}
              >
                {type}
              </Text>
            </Stack>
            <Select
              value={fieldMapping[name]}
              onChange={(e) => onFieldMappingChange(name, e.currentTarget.value)}
              flex={1}
            >
              <option label="" />
              {csvHeaders.map((header) => (
                <option key={header} value={header} label={header}>
                  {header}
                </option>
              ))}
            </Select>
          </Flex>
          <FieldPreview csvRow={csvRow} csvHeader={fieldMapping[name]} fieldType={type} />
        </Stack>
      ))}
    </Stack>
  );
};

export type FieldMapping = Record<TransactionField['name'], string | undefined>;
interface CsvUploadFormProps {
  csvFiles: CsvFile[] | undefined;
  setCsvFiles: Dispatch<SetStateAction<CsvFile[] | undefined>>;
  fieldMapping: FieldMapping;
  setFieldMapping: Dispatch<SetStateAction<FieldMapping>>;
  onSubmit: () => void;
  submitting: boolean;
}

//  TODO: Handle empty CSV files
export const CsvUploadForm = ({
  csvFiles,
  setCsvFiles,
  fieldMapping,
  setFieldMapping,
  onSubmit,
  submitting,
}: CsvUploadFormProps) => {
  const onFieldMappingChange = (field: string, csvHeader: string) => {
    setFieldMapping({
      ...fieldMapping,
      [field]: csvHeader,
    });
  };

  const csvFilePresent = csvFiles !== undefined && csvFiles.length > 0;

  useEffect(() => {
    if (csvFiles === undefined) {
      return;
    }

    const headers = csvFiles[0].headers;

    // TODO: Update to handle different platforms, this currently works for Mint
    setFieldMapping({
      Merchant: headers.includes('Description') ? 'Description' : undefined,
      Payee: headers.includes('Original Description') ? 'Original Description' : undefined,
      Amount: headers.includes('Amount') ? 'Amount' : undefined,
      Date: headers.includes('Date') ? 'Date' : undefined,
      Account: headers.includes('Account Name') ? 'Account Name' : undefined,
      Category: headers.includes('Category') ? 'Category' : undefined,
      Notes: headers.includes('Notes') ? 'Notes' : undefined,
      Type: headers.includes('Transaction Type') ? 'Transaction Type' : undefined,
    });
  }, [csvFiles, setFieldMapping]);

  return (
    <Stack spacing={4}>
      <FileSelect csvFiles={csvFiles} setCsvFiles={setCsvFiles} />
      {csvFilePresent && (
        <FieldMappingForm
          csvRow={csvFiles[0].data[0]}
          csvHeaders={csvFiles[0].headers}
          onFieldMappingChange={onFieldMappingChange}
          fieldMapping={fieldMapping}
        />
      )}
      <Button
        hidden={!csvFilePresent}
        alignSelf={'flex-end'}
        isLoading={submitting}
        onClick={onSubmit}
      >
        Import
      </Button>
    </Stack>
  );
};
