import { gql } from '@apollo/client';

export const CREATE_PLAID_LINK_TOKEN = gql`
  mutation CreatePlaidLinkToken($itemId: String) {
    createPlaidLinkToken(itemId: $itemId) {
      linkToken
    }
  }
`;

export interface CreatePlaidLinkTokenInput {
  itemId: string | null;
}

export interface CreatePlaidLinkTokenData {
  createPlaidLinkToken: {
    linkToken: string;
  };
}
