import { TransactionTable } from './TransactionTable';
import { AppRoot } from 'src/components/shared/AppRoot';

export function TransactionsApp() {
  return (
    <AppRoot noPadding={true}>
      <TransactionTable />
    </AppRoot>
  );
}
