import { EditIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const EditCategoriesButton = () => {
  const { t } = useTranslation();

  return (
    <Link to="/categories">
      <Button
        variant={'outline'}
        boxShadow="base"
        colorScheme="gray"
        leftIcon={<EditIcon />}
        size="sm"
        alignSelf="flex-end"
      >
        {t('common.buttons.editCategoryButtonText')}
      </Button>
    </Link>
  );
};
