import { gql } from '@apollo/client';

export const SET_CATEGORY = gql`
  mutation SetCategory($transactionId: String!, $categoryId: String) {
    setCategory(transactionId: $transactionId, categoryId: $categoryId) {
      id
      category {
        id
      }
    }
  }
`;

export interface SetCategoryInput {
  transactionId: string;
  categoryId: string | undefined;
}

export interface SetCategoryData {
  __typename: 'Mutation';
  setCategory: {
    id: string;
    category: {
      id: string;
      __typename: 'Category';
    } | null;
    __typename: 'Transaction';
  };
}

export const categoryOptimisticResponse = (
  transactionId: string,
  categoryId: string | undefined,
) => ({
  __typename: 'Mutation' as const,
  setCategory: {
    id: transactionId,
    category: categoryId
      ? {
          id: categoryId,
          __typename: 'Category' as const,
        }
      : null,
    __typename: 'Transaction' as const,
  },
});
