import { gql } from '@apollo/client';

enum RecurrenceInterval {
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
  YEARLY = 'Yearly',
}

export const GET_RECURRING_TRANSACTIONS = gql`
  query GetRecurringTransactions {
    recurringTransactions {
      id
      amount
      recurrenceInterval
      active
      payee
      transactions {
        id
        date
      }
    }
  }
`;

export interface GetRecurringTransactionsData {
  recurringTransactions: {
    id: string;
    amount: number;
    recurrenceInterval: RecurrenceInterval;
    active: boolean;
    payee: string;
    transactions: {
      id: string;
      date: Date;
    }[];
  }[];
}

export const GET_RECURRING_TRANSACTIONS_PLACEHOLDER: GetRecurringTransactionsData = {
  recurringTransactions: [
    {
      id: '123',
      amount: 10,
      active: true,
      recurrenceInterval: RecurrenceInterval.MONTHLY,
      payee: 'Spectrum',
      transactions: [
        {
          id: '123',
          date: new Date(),
        },
      ],
    },
    {
      id: '456',
      amount: 10,
      active: true,
      recurrenceInterval: RecurrenceInterval.MONTHLY,
      payee: 'Hulu',
      transactions: [
        {
          id: '123',
          date: new Date(),
        },
      ],
    },
    {
      id: '789',
      amount: 10,
      active: true,
      recurrenceInterval: RecurrenceInterval.MONTHLY,
      payee: 'Netflix',
      transactions: [
        {
          id: '123',
          date: new Date(),
        },
      ],
    },
  ],
};
