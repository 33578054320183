import { useQuery, useReactiveVar } from '@apollo/client';
import { Flex, Heading, Spinner, Text, useColorModeValue } from '@chakra-ui/react';
import { getColor } from '@chakra-ui/theme-tools';
import { theme } from '@flume-finance/ui';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { graphInterval } from 'src/cache';
import { intervalToChartParams } from 'src/components/insights/YearPicker';
import { ErrorWithRefetch } from 'src/components/shared/ErrorWithRefetch';
import { GET_CATEGORY_INSIGHT } from 'src/graphql/GetCategoryAggregation';
import { GET_CURRENT_USER } from 'src/graphql/GetCurrentUser';
import type {
  CategoryAggregation,
  GetCategoryAggregationQuery,
} from 'src/graphql/__generated__/graphql';
import { formatCurrency, sum, sumObj } from 'src/util';
import { unix } from 'src/util/dayjs';

const getColorForCategory = (key: string, data: GetCategoryAggregationQuery) => {
  return (
    data.categoryAggregation.aggregations.find((agg) => agg.category.name === key)?.category
      .color ?? 'gray'
  );
};

export const CategoryBar = () => {
  const graphIntervalValue = useReactiveVar(graphInterval);
  const chartParams = intervalToChartParams(graphIntervalValue);
  const { data: currentUserData } = useQuery(GET_CURRENT_USER);
  const { loading, error, data } = useQuery(GET_CATEGORY_INSIGHT, {
    variables: {
      startDate: unix(chartParams.startDate).toDate(),
      endDate: unix(chartParams.endDate).toDate(),
      interval: chartParams.aggregationInterval,
    },
    fetchPolicy: 'cache-and-network',
  });

  const cursorBgColor = useColorModeValue('gray.100', 'gray.700');
  const gridColor = useColorModeValue(theme.colors.gray[200], theme.colors.gray[600]);
  const fontColor = useColorModeValue(theme.colors.black, theme.colors.white);

  const fontProps = {
    stroke: getColor(theme, fontColor),
    fontSize: 12,
    fontWeight: 'bold',
    fontFamily: theme.fonts.body,
  };

  if (error) return <ErrorWithRefetch message={'Failed to load chart'} />;

  if (data === undefined) {
    return (
      <Flex justifyContent="center" alignItems="center" height={'100%'}>
        <Spinner size="lg" />
      </Flex>
    );
  }

  const dataset = data.categoryAggregation.xLabels.map((xLabel, i) => {
    const entry: Record<string, string | number> = {
      name: xLabel,
    };

    for (const agg of data.categoryAggregation.aggregations) {
      // Skip categories that had positive spend, only want to display categories that money was spent in rather than income
      if (agg.amounts[i] < 0) {
        entry[agg.category.name] = Math.abs(agg.amounts[i]);
      }
    }

    return entry;
  });

  const total = sumObj<CategoryAggregation>(data.categoryAggregation.aggregations, (agg) =>
    sum(agg.amounts.filter((amount) => amount < 0)),
  );

  const currencyCode = currentUserData?.currentUser.currencyCode ?? 'USD';

  return (
    <>
      <Flex justifyContent="space-between" alignItems="flex-start">
        <Heading as="h2" marginBottom={5}>
          {formatCurrency({ cents: total, currencyCode })}
        </Heading>
        {loading && (
          <Flex alignItems="center">
            <Spinner size={'sm'} mr={3} />
            <Text>Refreshing...</Text>
          </Flex>
        )}
      </Flex>
      <ResponsiveContainer width={'100%'} height={'90%'}>
        <BarChart data={dataset} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
          <XAxis dataKey="name" axisLine={false} tickLine={false} {...fontProps} />
          <Tooltip
            formatter={(val) => formatCurrency({ cents: -val, currencyCode, hideCents: true })}
            cursor={{ strokeWidth: 0, fill: getColor(theme, cursorBgColor) }}
            labelStyle={{ ...fontProps, fontSize: 12 } as React.CSSProperties}
            itemStyle={{ ...fontProps, fontSize: 16 } as React.CSSProperties}
            contentStyle={{
              borderRadius: 8,
              backgroundColor: getColor(theme, cursorBgColor),
              borderColor: getColor(theme, gridColor),
            }}
          />
          <YAxis
            tickFormatter={(val: number) => formatCurrency({ cents: -val, currencyCode })}
            axisLine={false}
            tickLine={false}
            {...fontProps}
          />
          <CartesianGrid stroke={gridColor} vertical={false} />
          {Object.keys(dataset[0])
            .sort((a, b) =>
              getColorForCategory(a, data).localeCompare(getColorForCategory(b, data)),
            )
            .map((key) => {
              if (key === 'name') {
                return;
              }
              const color = getColorForCategory(key, data);

              return (
                <Bar
                  stackId={'category'}
                  key={key}
                  type="monotone"
                  dataKey={key}
                  fillOpacity={1}
                  fill={getColor(theme, `${color}.400`)}
                />
              );
            })}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};
