import { useQuery } from '@apollo/client';
import { Navigate, useLocation } from 'react-router-dom';
import { GET_CURRENT_USER, getCurrentUserCallback } from 'src/graphql/GetCurrentUser';

interface PrivateRouteProps {
  children: JSX.Element;
  redirectTo?: string;
}

/**
 * A React Router compatible route component that gates the given page behind authorization.
 *
 * If the user is already logged in the page will load, otherwise they are redirected to login
 *
 * NOTE: [Tech Debt] This route wrapper has gotten fairly janky with all the additional logic around the sign up flow pages. Should refactor and clean this up at some point
 */
export const PrivateRoute = ({ children, redirectTo = '/login' }: PrivateRouteProps) => {
  const location = useLocation();
  const {
    data: currentUserData,
    loading,
    error,
  } = useQuery(GET_CURRENT_USER, {
    onCompleted: getCurrentUserCallback,
  });

  // NOTE: Assume the user has a valid session if there is a local storage entry, this enables a better skeleton loading experience
  const assumeLoggedIn = localStorage.getItem('isLoggedIn') === 'true' && loading;
  const isEmailVerified = currentUserData?.currentUser.isEmailVerified;

  if (
    // Redirect away from the signup pages if the user has already completed them
    location.pathname === '/verify-email' &&
    isEmailVerified &&
    !assumeLoggedIn
  ) {
    return <Navigate to="/" />;
  } else if (
    assumeLoggedIn || // Load the overview page to have a good loading experience in the general case
    isEmailVerified || // After loading, stay in the app assuming they are email verified
    (!isEmailVerified && location.pathname === '/verify-email') || // If they are on the email verification page and have not verified their email
    error // If there is an error connecting to the database, stay on the current page to avoid an infinite redirect loop between public and private
  ) {
    return children;
  } else if (isEmailVerified === false) {
    return <Navigate to={'/verify-email'} replace />;
  } else {
    return <Navigate to={redirectTo} replace />;
  }
};
