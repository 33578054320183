import { gql } from '@apollo/client';

export const DELETE_CATEGORY_GROUP = gql`
  mutation DeleteCategoryGroup($categoryGroupId: String!) {
    deleteCategoryGroup(categoryGroupId: $categoryGroupId) {
      id
    }
  }
`;

export interface DeleteCategoryGroupData {
  deleteCategoryGroup: {
    id: string;
  };
}

export interface DeleteCategoryGroupInput {
  categoryGroupId: string;
}
