import {
  type ComponentWithAs,
  Flex,
  Icon,
  type IconProps,
  Text,
  type TypographyProps,
} from '@chakra-ui/react';
import { theme } from '@flume-finance/ui';
import type { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

const LinkItem = ({
  name,
  to,
  currentPath,
  LinkIcon,
  expanded,
  onClick,
  fontSize = 'xs',
}: {
  name: string;
  to: string;
  currentPath: string;
  LinkIcon: FunctionComponent<React.SVGProps<SVGSVGElement>> | ComponentWithAs<'svg', IconProps>;
  expanded: boolean;
  fontSize?: TypographyProps['fontSize'];
  onClick?: () => void;
}) => {
  const isActive = to === currentPath;

  return (
    <Link
      to={to}
      style={{
        width: '100%',
        borderRadius: theme.radii.lg,
        overflow: 'hidden',
      }}
      onClick={onClick}
    >
      <Flex
        flexDir={'row'}
        alignItems={'center'}
        _hover={{ bg: 'hoverBg' }}
        bg={isActive ? 'hoverBg' : undefined}
        p={2}
        gap={4}
        borderRadius={'lg'}
      >
        <Icon as={LinkIcon} fill={'iconColor'} width={'1.125rem'} height={'1.125rem'} />
        <Text
          fontWeight={'semibold'}
          transitionProperty={'visibility opacity'}
          transitionDuration={'150ms'}
          visibility={expanded ? 'visible' : 'hidden'}
          opacity={expanded ? 1 : 0}
          fontSize={fontSize}
        >
          {name}
        </Text>
      </Flex>
    </Link>
  );
};

export { LinkItem };
