import { Flex, Container } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import { AppRoot } from 'src/components/shared/AppRoot';
import { HeadingWithMenu } from 'src/components/shared/HeadingWithMenu';

export function SettingsApp() {
  return (
    <AppRoot>
      <Flex justifyContent="flex-start" flex={1} gap={4} maxW={'100ch'} flexDir="column">
        <HeadingWithMenu />
        <Container
          variant={'card'}
          maxW="100%"
          flexGrow={1}
          flexBasis={{ base: '100%', md: 0 }}
          p={{ base: 4, md: 8 }}
        >
          <Outlet />
        </Container>
      </Flex>
    </AppRoot>
  );
}
