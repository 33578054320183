import { Icon, Tag, Text } from '@chakra-ui/react';
import AccountIcon from 'src/assets/icons/navbar/icons8-merchant-account.svg?react';

function AccountTag(props: { name: string }) {
  return (
    <Tag colorScheme={'gray'} gap={2}>
      <Icon as={AccountIcon} fill={'coloredIconColor'} />
      <Text isTruncated>{props.name}</Text>
    </Tag>
  );
}

export { AccountTag };
