import { gql } from '@apollo/client';
import { TriggerField } from '@flume-finance/common';

export type AutomationSuggestionStatus = 'PENDING' | 'ACCEPTED' | 'REJECTED';

export const GET_PENDING_AUTOMATION_SUGGESTIONS = gql`
  query GetPendingAutomationSuggestions {
    pendingAutomationSuggestions {
      id
      field
      value
      status
      automation {
        id
      }
    }
  }
`;

export interface GetPendingAutomationSuggestionsData {
  pendingAutomationSuggestions: {
    __typename: 'AutomationSuggestion';
    id: string;
    field: TriggerField;
    value: string;
    status: AutomationSuggestionStatus;
    automation: { id: string; __typename: 'Automation' } | null;
  }[];
}

export const GET_PENDING_AUTOMATION_SUGGESTIONS_PLACEHOLDER: GetPendingAutomationSuggestionsData = {
  pendingAutomationSuggestions: [
    {
      __typename: 'AutomationSuggestion' as const,
      id: '123',
      field: TriggerField.PAYEE,
      value: 'Amazon',
      status: 'PENDING',
      automation: null,
    },
    {
      __typename: 'AutomationSuggestion' as const,
      id: '456',
      field: TriggerField.PAYEE,
      value: 'Amazon',
      status: 'PENDING',
      automation: null,
    },
    {
      __typename: 'AutomationSuggestion' as const,
      id: '789',
      field: TriggerField.PAYEE,
      value: 'Amazon',
      status: 'PENDING',
      automation: null,
    },
  ],
};
