import { type ReactNode, useMemo, useRef, useCallback, useEffect } from 'react';
import {
  Table,
  Text,
  Thead,
  Th,
  Tr,
  Tbody,
  Td,
  Container,
  TableContainer,
  TableCaption,
  Spinner,
  Flex,
  Skeleton,
  Spacer,
  IconButton,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Button,
  PopoverFooter,
  PopoverHeader,
  Circle,
  FormControl,
  FormLabel,
  Portal,
  useBreakpointValue,
  Box,
} from '@chakra-ui/react';
import DotMenuIcon from 'src/assets/icons/icons8-menu-vertical.svg?react';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  useReactTable,
  type Row,
  getCoreRowModel,
  flexRender,
  getSortedRowModel,
  type SortingState,
  type ColumnDef,
  type Cell,
  type VisibilityState,
  type OnChangeFn,
  type RowSelectionState,
  type ColumnFiltersState,
  getFilteredRowModel,
  type Column,
  type Table as ReactTable,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import type { ApolloError } from '@apollo/client';
import FilterIcon from 'src/assets/icons/icons8-slider.svg?react';

import { ErrorWithRefetch } from 'src/components/shared/ErrorWithRefetch';
import { Search } from 'src/components/transactions/Search';
import { useTranslation } from 'react-i18next';
import { HeadingWithMenu } from './HeadingWithMenu';
import { ChakraMotionBox } from 'src/components/shared/ChakraMotionBox';
import { AnimatePresence } from 'framer-motion';

const getIsSomeColumnsFiltered = <T,>(table: ReactTable<T>) =>
  table.getAllColumns().some((c) => c.getIsFiltered());

const getSomeColumnsCanFilter = <T,>(table: ReactTable<T>) => {
  return table.getAllColumns().some((c) => c.getCanFilter());
};

const getSomeColumnsCanGlobalFilter = <T,>(table: ReactTable<T>) => {
  return table.getAllColumns().some((c) => c.columnDef.enableGlobalFilter);
};

const TableFilters = <T,>({ table }: { table: ReactTable<T> }) => {
  return (
    <Popover isLazy>
      <PopoverTrigger>
        <IconButton
          colorScheme={'gray'}
          variant={'outline'}
          icon={
            <>
              <Circle
                size={2}
                bg={'indicatorDot'}
                position="absolute"
                top={1}
                right={1}
                hidden={!getIsSomeColumnsFiltered(table)}
              />
              <Icon as={FilterIcon} fill={'iconColor'} width={4} height={4} />
            </>
          }
          aria-label="Edit Filters"
          type="button"
          size="sm"
        />
      </PopoverTrigger>
      <Portal>
        <Box w="full" h="full" zIndex="popover" position={'relative'}>
          <PopoverContent mr={2} boxShadow={'md'}>
            <PopoverHeader>
              <Text textTransform={'uppercase'} fontSize="xs" fontWeight={'semibold'}>
                Filters
              </Text>
            </PopoverHeader>
            <PopoverArrow />
            <PopoverCloseButton tabIndex={-1} />
            <PopoverBody>
              <Flex flexDir={'column'} gap={4}>
                {table.getAllColumns().map((c) => {
                  const FilterComponent = c.columnDef.meta?.FilterComponent;
                  if (FilterComponent === undefined) {
                    return null;
                  }

                  return (
                    <Flex key={c.columnDef.id} flexDirection={'column'}>
                      <FormLabel>{c.columnDef.header?.toString()}</FormLabel>
                      <FormControl>
                        <FilterComponent
                          value={c.getFilterValue() as string}
                          onChange={(value: string | undefined) => c.setFilterValue(value)}
                        />
                      </FormControl>
                    </Flex>
                  );
                })}
              </Flex>
            </PopoverBody>
            <PopoverFooter display="flex" justifyContent={'flex-end'} gap={2}>
              <Button colorScheme="gray" onClick={() => table.resetColumnFilters()}>
                Reset
              </Button>
            </PopoverFooter>
          </PopoverContent>
        </Box>
      </Portal>
    </Popover>
  );
};

interface ColumnFilterProps<T> {
  column: Column<T>;
}
const ColumnFilter = <T,>({ column }: ColumnFilterProps<T>) => {
  const columnName = column.columnDef.header?.toString() ?? 'Column';
  const FilterComponent = column.columnDef.meta?.FilterComponent;

  if (FilterComponent === undefined) {
    return null;
  }

  return (
    <Popover isLazy>
      <PopoverTrigger>
        <IconButton
          size="xs"
          variant={'ghost'}
          colorScheme="gray"
          aria-label={`${columnName} filters`}
          icon={<Icon as={DotMenuIcon} fill={'iconColor'} w={4} h={4} />}
          hidden={!column.getCanFilter()}
          tabIndex={-1}
        />
      </PopoverTrigger>
      <Portal>
        <Box w="full" h="full" zIndex="popover" position={'relative'}>
          <PopoverContent mr={2}>
            <PopoverHeader>
              <Text
                textTransform={'uppercase'}
                fontSize="xs"
                fontWeight={'semibold'}
              >{`Filter by ${columnName}`}</Text>
            </PopoverHeader>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody my={4}>
              <FilterComponent
                value={column.getFilterValue() as string}
                onChange={(value: string | undefined) => column.setFilterValue(value)}
              />
            </PopoverBody>
            <PopoverFooter display="flex" justifyContent={'flex-end'} gap={2}>
              <Button colorScheme="gray" onClick={() => column.setFilterValue(undefined)}>
                Reset
              </Button>
            </PopoverFooter>
          </PopoverContent>
        </Box>
      </Portal>
    </Popover>
  );
};

function TableCell<T>({ cell, children }: { cell: Cell<T, unknown>; children: ReactNode }) {
  return useMemo(
    () => (
      <Td
        flexBasis={cell.column.columnDef.meta?.flexBasis}
        flexGrow={cell.column.columnDef.meta?.flexGrow}
        flexShrink={cell.column.columnDef.meta?.flexShrink ?? undefined}
        width={cell.column.getSize()}
        minW={0}
        overflow="hidden"
      >
        {children}
      </Td>
    ),
    [cell, children],
  );
}

function TableRow<T extends { id: string }>(props: {
  row: Row<T>;
  onRowClick: (entity: T) => void;
  getRowColor?: (row: Row<T>) => string;
  transform: string;
}) {
  const visibleCells = props.row.getVisibleCells();
  const cells = useMemo(
    () =>
      visibleCells.map((cell) => (
        <TableCell key={cell.id} cell={cell}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </TableCell>
      )),
    [visibleCells],
  );

  return (
    <Tr
      onClick={() => props.onRowClick(props.row.original)}
      cursor="pointer"
      alignItems={'center'}
      display="flex"
      bg={props.getRowColor?.(props.row)}
      userSelect={'none'}
      transform={props.transform}
      position="absolute"
      top={0}
      left={0}
      right={0}
    >
      {cells}
    </Tr>
  );
}

interface SharedTableProps<T> {
  columns: ColumnDef<T>[];
  data: T[];
  refetch?: () => void;
  fetchMore: () => void;
  hasMore: boolean;
  isLoading: boolean;
  isFetchingMore?: boolean;
  isInitialLoaded: boolean;
  onRowClick: (entity: T) => void;
  getRowColor?: (row: Row<T>) => string;
  error: ApolloError | undefined;
  resourceNamePlural: string;
  visibilityState?: VisibilityState;
  emptyComponent: JSX.Element;
  ActionRow?: (props: {
    rowSelection: RowSelectionState | undefined;
    data: T[];
    unselectAll: () => void;
  }) => JSX.Element;
  showHeading?: boolean;
  showHeadingBreadcrumbs?: boolean;
  sortState?: {
    sorting: SortingState;
    setSorting: OnChangeFn<SortingState>;
  };
  columnFilterState?: {
    columnFilters: ColumnFiltersState;
    setColumnFilters: OnChangeFn<ColumnFiltersState>;
  };
  rowSelectionState?: {
    rowSelection: RowSelectionState;
    setRowSelection: OnChangeFn<RowSelectionState>;
  };
  globalFilterState?: {
    globalFilter: string | null;
    setGlobalFilter: OnChangeFn<string | null>;
  };
}

const ActionPalette = <T,>({
  rowSelection,
  data,
  ActionRow,
  unselectAll,
}: {
  data: SharedTableProps<T>['data'];
  ActionRow: SharedTableProps<T>['ActionRow'];
  rowSelection: RowSelectionState | undefined;
  unselectAll: () => void;
}) => {
  const selection = Object.keys(rowSelection ?? {});

  return (
    <AnimatePresence>
      {selection.length > 0 && (
        <ChakraMotionBox
          position={'absolute'}
          left={0}
          right={0}
          w="full"
          variants={{
            initial: {
              opacity: 0,
              bottom: '1rem',
            },
            base: {
              bottom: '2rem',
              opacity: 1,
            },
            exit: {
              opacity: 0,
            },
          }}
          animate={'base'}
          initial={'initial'}
          exit={'exit'}
          justifyContent="center"
          display={'flex'}
          transition={{ type: 'tween', ease: 'linear', duration: '0.1' }}
        >
          <Container
            variant={'card'}
            py={3}
            px={4}
            w="20rem"
            boxShadow={'palette'}
            borderColor="borderColor"
            borderWidth={'1px'}
          >
            {ActionRow && (
              <ActionRow rowSelection={rowSelection} data={data} unselectAll={unselectAll} />
            )}
          </Container>
        </ChakraMotionBox>
      )}
    </AnimatePresence>
  );
};

const SharedTable = <T extends { id: string }>(props: SharedTableProps<T>) => {
  const { t } = useTranslation();
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const tableColumns = useMemo<ColumnDef<T>[]>(
    () =>
      !props.isInitialLoaded
        ? props.columns.map((column) => ({
            ...column,
            cell: (cell) => (
              <Flex py={'1.5'}>
                <Skeleton
                  height={5}
                  minW={6}
                  maxWidth={'full'}
                  flexBasis={cell.column.columnDef.meta?.flexBasis}
                  flexGrow={cell.column.columnDef.meta?.flexGrow}
                  flexShrink={cell.column.columnDef.meta?.flexShrink ?? 0}
                />
              </Flex>
            ),
          }))
        : props.columns,
    [props.isInitialLoaded, props.columns],
  );

  const rowHeight: number =
    useBreakpointValue({ base: 50, md: 41 }, { ssr: false, fallback: 'md' }) ?? 41;

  const TABLE_HEADER_HEIGHT =
    useBreakpointValue({ base: 25, md: 32 }, { ssr: false, fallback: 'md' }) ?? 32;
  const estimateSize = useCallback(() => rowHeight, [rowHeight]);

  const table = useReactTable({
    data: props.data,
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualSorting: true,
    manualFiltering: true,
    manualPagination: true,
    enableMultiSort: false,
    enableColumnFilters: true,
    enableFilters: true,
    enableGlobalFilter: true,
    pageCount: -1,
    defaultColumn: {
      minSize: 25,
    },
    state: {
      sorting: props.sortState?.sorting,
      rowSelection: props.rowSelectionState?.rowSelection,
      columnFilters: props.columnFilterState?.columnFilters,
      columnVisibility: props.visibilityState,
      globalFilter: props.globalFilterState?.globalFilter,
    },
    onSortingChange: (onChangeFn) => {
      props.sortState?.setSorting(onChangeFn);
      table.resetRowSelection();
    },
    onColumnFiltersChange: (onChangeFn) => {
      props.columnFilterState?.setColumnFilters(onChangeFn);
      table.resetRowSelection();
    },
    onGlobalFilterChange: (onChangeFn) => {
      props.globalFilterState?.setGlobalFilter(onChangeFn);
      table.resetRowSelection();
    },
    onRowSelectionChange: props.rowSelectionState?.setRowSelection,
    getRowId: (row) => row.id,
  });

  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => tableContainerRef.current,
    count: rows.length,
    overscan: 15,
    estimateSize,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();
  const lastItem = virtualRows[virtualRows.length - 1];

  useEffect(() => {
    if (!lastItem) {
      return;
    }

    if (
      lastItem.index >= rows.length - 1 &&
      props.hasMore &&
      !props.isFetchingMore &&
      !props.error
    ) {
      props.fetchMore();
    }
  }, [props.hasMore, props.fetchMore, rows.length, props.isFetchingMore, lastItem, props.error]);

  if (
    props.data.length === 0 &&
    !getIsSomeColumnsFiltered(table) &&
    !props.globalFilterState?.globalFilter
  ) {
    return (
      <Box display={'flex'} flex={1} maxWidth={'100%'} flexDir="column">
        <Spacer flexBasis={'30%'} />
        {props.emptyComponent}
        <Spacer flexBasis={'70%'} />
      </Box>
    );
  }

  return (
    <Flex direction="column" flex={1} gap={2} overflow="hidden" minW={0}>
      <Flex justifyContent="space-between" gap={2} pt={2} px={2}>
        <Flex gap={1} flex={1} alignItems={'flex-start'} justifyContent={'space-between'}>
          {(props.showHeading ?? true) && (
            <HeadingWithMenu showBreadcrumbs={props.showHeadingBreadcrumbs ?? false} />
          )}
          {getSomeColumnsCanGlobalFilter(table) && props.globalFilterState && (
            <Search value={props.globalFilterState.globalFilter} onChange={table.setGlobalFilter} />
          )}
        </Flex>
        {getSomeColumnsCanFilter(table) && <TableFilters table={table} />}
      </Flex>

      <TableContainer
        flex={1}
        ref={tableContainerRef}
        px={0}
        overflowY="scroll"
        opacity={props.isLoading ? 0.3 : undefined}
        pointerEvents={
          (props.isLoading || !props.isInitialLoaded) && !props.error ? 'none' : undefined
        }
        position="relative"
      >
        {props.isFetchingMore && (
          <Flex
            my={2}
            position={'absolute'}
            justify="center"
            alignItems={'center'}
            top={lastItem?.start ?? 0 + estimateSize() + TABLE_HEADER_HEIGHT}
            left={0}
            right={0}
          >
            <Spinner size="sm" color="subtleText" />
          </Flex>
        )}
        <Table variant="default" height={`${rowVirtualizer.getTotalSize()}px`}>
          <Thead position="sticky" top={0} zIndex={'sticky'}>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id} display={'flex'} overflow={'hidden'}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      title={
                        header.column.getCanSort()
                          ? `Toggle ${header.column.columnDef.header?.toString() ?? ''} Sort`
                          : undefined
                      }
                      cursor={header.column.getCanSort() ? 'pointer' : undefined}
                      onClick={header.column.getToggleSortingHandler()}
                      flexBasis={header.column.columnDef.meta?.flexBasis}
                      flexGrow={header.column.columnDef.meta?.flexGrow}
                      flexShrink={header.column.columnDef.meta?.flexShrink ?? undefined}
                      minW={0}
                      display="flex"
                      justifyContent="flex-start"
                      alignItems={'center'}
                    >
                      <Flex justifyContent="space-between" flex={1}>
                        <Flex alignItems={'center'} gap={1}>
                          <Circle
                            size={2}
                            bg={'indicatorDot'}
                            hidden={!header.column.getIsFiltered()}
                          />
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {header.column.getIsSorted() ? (
                            header.column.getIsSorted() === 'desc' ? (
                              <TriangleDownIcon
                                marginLeft={-0.5}
                                aria-label={t('common.table.sortDescLabel') ?? undefined}
                              />
                            ) : (
                              <TriangleUpIcon
                                marginLeft={-0.5}
                                aria-label={t('common.table.sortAscLabel') ?? undefined}
                              />
                            )
                          ) : null}
                        </Flex>
                        <ColumnFilter column={header.column} />
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          {props.error && (
            <TableCaption>
              <ErrorWithRefetch
                message={`Failed to load your ${props.resourceNamePlural}.`}
                refetch={props.refetch}
              />
            </TableCaption>
          )}
          {props.data.length === 0 && (
            <TableCaption>{`No ${props.resourceNamePlural} found.`}</TableCaption>
          )}

          {props.error === undefined && (
            <Tbody>
              {virtualRows.map((virtualRow) => {
                const row = rows[virtualRow.index];
                return (
                  <TableRow
                    key={row.id}
                    row={row}
                    onRowClick={props.onRowClick}
                    getRowColor={props.getRowColor}
                    transform={`translateY(${virtualRow.start + TABLE_HEADER_HEIGHT}px)`}
                  />
                );
              })}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      <ActionPalette
        key="action-palette"
        data={props.data}
        ActionRow={props.ActionRow}
        rowSelection={props.rowSelectionState?.rowSelection}
        unselectAll={() => table.resetRowSelection()}
      />
    </Flex>
  );
};

export { SharedTable };
