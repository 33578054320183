import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Stack,
  theme,
} from '@chakra-ui/react';
import {
  type Dispatch,
  type KeyboardEventHandler,
  type SetStateAction,
  useRef,
  useState,
} from 'react';
import { AccountType, type CreateManualAccountArgs } from 'src/graphql/__generated__/graphql';
import { formatCurrency } from 'src/util';

interface NumberFieldProps {
  fieldId: string;
  label: string;
  helperText?: string;

  hasBeenSubmitted: boolean;
  value: string;
  currencyCode?: string;
  onChange: (v: string) => void;
  onKeyPress?: KeyboardEventHandler<HTMLDivElement>;

  step?: number;
}
export const NumberField = ({
  fieldId,
  label,
  hasBeenSubmitted,
  value,
  onChange,
  currencyCode,
  helperText,
  onKeyPress,
  step = 1000,
}: NumberFieldProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const isError = hasBeenSubmitted && !ref.current?.validity.valid;
  const errorText = ref.current?.validationMessage;

  const format = (val: string) =>
    formatCurrency({ cents: Number(val), currencyCode: currencyCode ?? 'USD' });
  const parse = (val: string) => val.replace(/[^0-9\\-]/g, '');

  return (
    <FormControl isInvalid={isError} isRequired>
      <FormLabel
        htmlFor={fieldId}
        fontSize="xs"
        color="subtleText"
        textTransform={'uppercase'}
        fontWeight="bold"
      >
        {label}
      </FormLabel>
      <NumberInput
        id={fieldId}
        onChange={(v) => onChange(parse(v))}
        precision={2}
        step={step}
        value={format(value)}
        onKeyUp={onKeyPress}
      >
        <NumberInputField
          ref={ref}
          // Regex to match most common locale formatted currency strings
          // https://chat.openai.com/share/f5b10052-d63c-44df-ae64-f789bb7bafb5
          pattern={'(?:-)?(?:[A-Z]{1,3}s)?p{Sc}?[d{1,3}(?:[.,]d{3})*[.,]d{2}]'}
          onClick={() => ref.current?.setSelectionRange(0, ref.current.value.length)}
        />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      {isError ? (
        <FormErrorMessage>{errorText}</FormErrorMessage>
      ) : (
        <FormHelperText
          color={'subtleText'}
          display={(helperText?.length ?? 0) > 0 ? 'default' : 'none'}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

interface InputFieldProps {
  fieldId: string;
  label: string;
  placeholder: string;

  helperText?: string;

  hasBeenSubmitted: boolean;
  value: string;
  onChange: (v: string) => void;

  minLength?: number;
  maxLength?: number;
}

export const InputField = ({
  fieldId,
  label,
  placeholder,
  hasBeenSubmitted,
  value,
  onChange,
  helperText,
  minLength = 1,
  maxLength,
}: InputFieldProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const isError = hasBeenSubmitted && !ref.current?.validity.valid;
  const errorText = ref.current?.validationMessage;

  return (
    <FormControl isInvalid={isError} isRequired>
      <FormLabel
        htmlFor={fieldId}
        fontSize="xs"
        color="subtleText"
        textTransform={'uppercase'}
        fontWeight="bold"
      >
        {label}
      </FormLabel>
      <Input
        ref={ref}
        id={fieldId}
        type="text"
        autoComplete="off"
        minLength={minLength}
        maxLength={maxLength}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      {isError ? (
        <FormErrorMessage>{errorText}</FormErrorMessage>
      ) : (
        <FormHelperText color={'subtleText'}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

interface SelectFieldProps {
  fieldId: string;
  label: string;
  options: string[];
  helperText?: string;

  hasBeenSubmitted: boolean;
  value: string;
  onChange: (val: string) => void;
  valueFormatter?: (val: string) => string;
}

export const SelectField = ({
  fieldId,
  label,
  hasBeenSubmitted,
  value,
  onChange,
  helperText,
  options,
  valueFormatter,
}: SelectFieldProps) => {
  const ref = useRef<HTMLSelectElement>(null);
  const isError = hasBeenSubmitted && !ref.current?.validity.valid;
  const errorText = ref.current?.validationMessage;

  const formatFn = valueFormatter ?? ((v) => v.toLowerCase().replaceAll('_', ' '));

  return (
    <FormControl isInvalid={isError} isRequired>
      <FormLabel
        htmlFor={fieldId}
        fontSize="xs"
        color="subtleText"
        textTransform={'uppercase'}
        fontWeight="bold"
      >
        {label}
      </FormLabel>
      <Select
        ref={ref}
        id={fieldId}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        textTransform={'capitalize'}
      >
        {options.map((opt) => (
          <option key={opt} value={opt}>
            {formatFn(opt)}
          </option>
        ))}
      </Select>
      {isError ? (
        <FormErrorMessage>{errorText}</FormErrorMessage>
      ) : (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

interface CreateEditAccountFormProps {
  account: CreateManualAccountArgs;
  updateAccount: Dispatch<SetStateAction<CreateManualAccountArgs>>;
  setActiveIndex: Dispatch<SetStateAction<number>>;
  buttonText?: string;
}

export const CreateEditAccountForm = ({
  account,
  updateAccount,
  setActiveIndex,
  buttonText = 'Next',
}: CreateEditAccountFormProps) => {
  const formRef = useRef<HTMLFormElement>(null);

  // NOTE: Keep track of whether the form has been submitted so we don't show the form errors at first
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState<boolean>(false);

  const next = () => {
    setHasBeenSubmitted(true);
    if (formRef.current?.checkValidity()) {
      setActiveIndex(2);
    }
  };

  return (
    <form ref={formRef}>
      <Stack spacing={theme.space[4]}>
        <InputField
          fieldId="nickname"
          label={'Name'}
          placeholder="Chase Freedom Unlimited"
          onChange={(val: string) => updateAccount({ ...account, nickname: val })}
          value={account.nickname}
          hasBeenSubmitted={hasBeenSubmitted}
        />
        <SelectField
          fieldId="type"
          label={'Account Type'}
          value={account.type}
          onChange={(v) => updateAccount({ ...account, type: v as AccountType })}
          hasBeenSubmitted={hasBeenSubmitted}
          options={Object.values(AccountType)}
        />
        <InputField
          fieldId="accountNumber"
          label={'Last 4'}
          placeholder="1234"
          value={account.accountNumber}
          onChange={(val: string) => updateAccount({ ...account, accountNumber: val })}
          hasBeenSubmitted={hasBeenSubmitted}
          helperText={'Used to help you distinguish between accounts'}
          minLength={4}
          maxLength={4}
        />

        <Button alignSelf={'flex-end'} onClick={next}>
          {buttonText}
        </Button>
      </Stack>
    </form>
  );
};
