import {
  type ApolloCache,
  type DefaultContext,
  gql,
  type MutationUpdaterFunction,
} from '@apollo/client';
import { type FragmentCategoryGroupData, FRAGMENT_CATEGORY_GROUP } from './GetCategoryGroups';

export const CREATE_CATEGORY_GROUP = gql`
  mutation CreateCategoryGroup($name: String!) {
    createCategoryGroup(name: $name) {
      ...CategoryGroupFields
    }
  }
  ${FRAGMENT_CATEGORY_GROUP}
`;

export interface CreateCategoryGroupInput {
  name: string;
}

export type CreateCategoryGroupData = {
  __typename: 'Mutation';
  createCategoryGroup: FragmentCategoryGroupData & {
    __typename: 'CategoryGroup';
  };
};

export const updateCategoryGroupCache: MutationUpdaterFunction<
  CreateCategoryGroupData,
  CreateCategoryGroupInput,
  DefaultContext,
  ApolloCache<unknown>
> = (cache, { data }) => {
  if (!data) {
    return;
  }
  // Add the new category to the cache
  cache.modify({
    fields: {
      categoryGroups(existingRefs, { toReference }) {
        return [toReference(data.createCategoryGroup, true), ...existingRefs];
      },
    },
  });
};
