import { gql, type Reference } from '@apollo/client';
import type { ActionField, TriggerField, TriggerFieldMatcher } from '@flume-finance/common';
import type { GraphqlMutation } from 'src/util/graphql';
import { FRAGMENT_AUTOMATION_FIELDS } from './GetAutomations';

export interface ICreateAutomation {
  input: {
    createAutomationInput: {
      triggers: [
        {
          field: TriggerField;
          matcher: TriggerFieldMatcher;
          value: string;
        },
      ];
      actions: [
        {
          field: ActionField;
          value: string | null;
        },
      ];
      overrideExistingCategories: boolean;
    };
  };
  output: {
    createAutomation: {
      __typename: 'Automation';
      id: string;
      triggers: {
        id: string;
        field: TriggerField;
        matcher: TriggerFieldMatcher;
        value: string;
      }[];
      actions: {
        id: string;
        field: ActionField;
        value: string | null;
      }[];
    };
  };
}

export const CreateAutomation: GraphqlMutation<
  ICreateAutomation,
  {
    triggerField: TriggerField;
    triggerFieldMatcher: TriggerFieldMatcher;
    triggerValue: string;
    actionField: ActionField;
    actionFieldValue: string;
  }
> = {
  query: gql`
    mutation CreateAutomation($createAutomationInput: CreateAutomationInput!) {
      createAutomation(createAutomationInput: $createAutomationInput) {
        ...AutomationFields
      }
    }
    ${FRAGMENT_AUTOMATION_FIELDS}
  `,

  update: (cache, { data }) => {
    if (data) {
      // Add the new automation to the cache
      cache.modify<{ automations: Reference[] }>({
        fields: {
          automations(existingAutomations, { toReference }) {
            const newAutomationRef = toReference(data.createAutomation, true);

            if (newAutomationRef === undefined) {
              return existingAutomations;
            }

            return [...existingAutomations, newAutomationRef];
          },
        },
      });

      // TODO: [PERFORMANCE] only the category subfield needs to be evicted, but it's not obvious how to do that
      // Evict all existing transaction categories from cache
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'transactions' });
      cache.gc();
    }
  },

  optimisticResponse: ({
    triggerField,
    triggerFieldMatcher,
    triggerValue,
    actionField,
    actionFieldValue,
  }) => ({
    createAutomation: {
      __typename: 'Automation' as const,
      id: `tmp-automation-${Math.random()}`,
      triggers: [
        {
          id: `tmp-trigger-${Math.random()}`,
          field: triggerField,
          matcher: triggerFieldMatcher,
          value: triggerValue,
        },
      ],
      actions: [
        {
          id: `tmp-action-${Math.random()}`,
          field: actionField,
          value: actionFieldValue ?? null,
        },
      ],
    },
  }),
};
