import { Navigate } from 'react-router-dom';

interface PublicOnlyRouteProps {
  children: JSX.Element;
  redirectTo?: string;
}

export const PublicOnlyRoute = ({ children, redirectTo = '/' }: PublicOnlyRouteProps) => {
  // NOTE: Can't use GetCurrentUser query here because it will return an error when not logged in which in turn triggers the global error handler which trigger the handleLogout method which clears the cache and redirects to the same page repeating the process
  const assumeLoggedIn = localStorage.getItem('isLoggedIn') === 'true';

  return assumeLoggedIn ? <Navigate to={redirectTo} replace /> : children;
};
