import { useReactiveVar } from '@apollo/client';
import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { Flex, Spinner, Text } from '@chakra-ui/react';
import { SaveState, saveState } from 'src/cache';

const Saving = () => (
  <>
    <Spinner size="sm" mr={2} />
    <Text fontWeight="semibold" fontSize={{ base: 'xs', md: 'sm' }}>
      Saving...
    </Text>
  </>
);

const Saved = () => {
  return (
    <Flex gap={2} alignItems="center">
      <CheckCircleIcon w={'1rem'} h={'1rem'} />
      <Text
        fontWeight="semibold"
        fontSize={{ base: 'xs', md: 'sm' }}
        visibility={{ base: 'hidden', md: 'visible' }}
      >
        All changes saved.
      </Text>
    </Flex>
  );
};

const Errored = () => {
  return (
    <>
      <WarningIcon mr={2} w={5} h={5} color="red.500" />
      <Text color="red.500" fontWeight="semibold" fontSize={{ base: 'xs', md: 'sm' }}>
        {'Error saving'}
      </Text>
    </>
  );
};

const Indicator = ({ saveStateValue }: { saveStateValue: SaveState }) => {
  switch (saveStateValue) {
    case SaveState.SAVING:
      return <Saving />;
    case SaveState.SAVED:
      return <Saved />;
    case SaveState.ERRORED:
      return <Errored />;
  }
};

export function SyncIndicator() {
  const saveStateValue = useReactiveVar(saveState);

  return (
    <Flex justify="center" alignItems="center">
      <Indicator saveStateValue={saveStateValue} />
    </Flex>
  );
}
