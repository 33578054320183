import { gql } from '@apollo/client';

export const DELETE_TRANSFER = gql`
  mutation DeleteTransfer($transferId: String!) {
    deleteTransfer(transferId: $transferId) {
      id
    }
  }
`;

export interface DeleteTransferData {
  deleteTransfer: {
    id: string;
  };
}

export interface DeleteTransferInput {
  transferId: string;
}
