import { RecurringTransactionTable } from 'src/components/recurringTransactions/RecurringTransactionTable';
import { AppRoot } from 'src/components/shared/AppRoot';

export function RecurringTransactionApp() {
  return (
    <AppRoot>
      <RecurringTransactionTable />
    </AppRoot>
  );
}
