import { Flex } from '@chakra-ui/react';
import { PlaidImportIndicator } from 'src/components/transactions/PlaidImportIndicator';
import { AppNav } from './AppNav';
import { SubscriptionExpirationToast } from './SubscriptionExpirationToast';
import { SubscriptionLoadingIndicator } from 'src/components/settings/billing/checkout/SubscriptionLoadingIndicator';
import { type ReactNode, useEffect } from 'react';
import { client } from 'src';

export function AppRoot({
  children,
  noPadding = false,
}: {
  children: ReactNode;
  noPadding?: boolean;
}) {
  // Refetch active queries when the window regains focus
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        client.refetchQueries({
          include: 'active',
        });
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  });

  return (
    <Flex height="full" flexDir={'row'} flexFlow={'row'} overflow="hidden">
      <AppNav />
      <Flex
        flexGrow={1}
        justifyContent={{ md: 'center' }}
        overflow="auto"
        p={noPadding ? 0 : { base: 2, md: 4 }}
      >
        {children}
      </Flex>
      <SubscriptionLoadingIndicator />
      <PlaidImportIndicator />
      <SubscriptionExpirationToast />
    </Flex>
  );
}
