import { gql } from '@apollo/client';

export const GET_STRIPE_PRODUCTS = gql`
  query GetStripeProduct {
    stripeProducts {
      id
      name
      description
      stripePrices {
        id
        amount
        currency
        recurrenceInterval
      }
    }
  }
`;

export interface GetStripeProductsData {
  stripeProducts: {
    id: string;
    name: string;
    description: string;
    stripePrices: {
      id: string;
      amount: number;
      currency: string;
      recurrenceInterval: 'MONTHLY' | 'YEARLY';
    }[];
  }[];
}

export const GET_STRIPE_PRODUCTS_PLACEHOLDER: GetStripeProductsData = {
  stripeProducts: [
    {
      id: 'stripeProduct-123',
      name: 'Placeholder name',
      description: 'Some placeholder product description here',
      stripePrices: [
        {
          id: 'stripePrices-123',
          amount: 1500,
          currency: 'USD',
          recurrenceInterval: 'MONTHLY',
        },
        {
          id: 'stripePrices-456',
          amount: 15000,
          currency: 'USD',
          recurrenceInterval: 'YEARLY',
        },
      ],
    },
  ],
};
