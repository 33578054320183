import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useClipboard,
  Text,
  InputGroup,
  InputRightElement,
  IconButton,
  Icon,
} from '@chakra-ui/react';
import { GoCopy, GoCheck } from 'react-icons/go';

interface ForwardInstructionsEmailAccountProps {
  onClose: () => void;
  venmoAccountId: string;
}

export const ForwardInstructionsEmailAccount = ({
  onClose,
  venmoAccountId,
}: ForwardInstructionsEmailAccountProps) => {
  const {
    onCopy: onCopyFrom,
    value: fromEmail,
    hasCopied: hasCopiedFrom,
  } = useClipboard('venmo@venmo.com');
  const {
    onCopy: onCopyTo,
    value: toEmail,
    hasCopied: hasCopiedTo,
  } = useClipboard(`${venmoAccountId}@${import.meta.env.VITE_PARSE_EMAIL_DOMAIN}`);

  return (
    <Flex flexDir={'column'} gap={6}>
      <Text>
        Setup a forwarding rule in your email to send a copy of your Venmo transaction emails to
        Flume
      </Text>
      <Flex gap={2} flexDir="column">
        <FormControl id="from">
          <FormLabel fontSize="xs" color="subtleText" textTransform={'uppercase'} fontWeight="bold">
            From
          </FormLabel>
          <InputGroup size="md">
            <Input value={fromEmail} readOnly />
            <InputRightElement>
              <IconButton
                colorScheme={'gray'}
                aria-label="Copy"
                size="sm"
                onClick={onCopyFrom}
                icon={<Icon as={hasCopiedFrom ? GoCheck : GoCopy} />}
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <FormControl id="to">
          <FormLabel fontSize="xs" color="subtleText" textTransform={'uppercase'} fontWeight="bold">
            To
          </FormLabel>
          <InputGroup size="md">
            <Input value={toEmail} readOnly textOverflow={'ellipsis'} />
            <InputRightElement>
              <IconButton
                colorScheme={'gray'}
                aria-label="Copy"
                size="sm"
                onClick={onCopyTo}
                icon={<Icon as={hasCopiedTo ? GoCheck : GoCopy} />}
              />
            </InputRightElement>
          </InputGroup>{' '}
        </FormControl>
      </Flex>
      <Button onClick={onClose} alignSelf="flex-end">
        Done
      </Button>
    </Flex>
  );
};
