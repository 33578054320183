import { gql } from 'src/graphql/__generated__/gql';
import {
  AccountType,
  ExternalPlatform,
  type GetAccountsQuery,
  SyncMode,
} from 'src/graphql/__generated__/graphql';

export const GET_ACCOUNTS = gql(/* GraphQL */ `
  query GetAccounts {
    accounts {
      id
      nickname
      type
      balance
      accountNumber
      syncMode
      item {
        id
        externalPlatformType
        lastSyncedAt
        expiresAt
        institutionName
      }
    }
  }
`);

export const GET_ACCOUNTS_PLACEHOLDER: GetAccountsQuery = {
  accounts: [
    {
      id: '123',
      nickname: 'Credit Card',
      type: AccountType.CreditCard,
      balance: -1000n,
      syncMode: SyncMode.Manual,
      accountNumber: '1234',
      item: {
        id: 'item-123',
        lastSyncedAt: new Date('2023-01-01'),
        expiresAt: null,
        externalPlatformType: ExternalPlatform.Plaid,
        institutionName: 'Chase',
      },
    },
    {
      id: '456',
      nickname: 'Credit Card',
      type: AccountType.CreditCard,
      balance: 1000n,
      accountNumber: '4567',
      syncMode: SyncMode.Automatic,
      item: {
        id: 'item-123',
        lastSyncedAt: new Date('2023-01-01'),
        expiresAt: null,
        externalPlatformType: ExternalPlatform.Plaid,
        institutionName: 'Chase',
      },
    },
    {
      id: '789',
      nickname: 'Credit Card',
      type: AccountType.CreditCard,
      balance: 1000n,
      accountNumber: '7890',
      syncMode: SyncMode.Automatic,
      item: {
        id: 'item-456',
        lastSyncedAt: new Date('2023-01-01'),
        expiresAt: null,
        externalPlatformType: ExternalPlatform.Plaid,
        institutionName: 'Bank Of America',
      },
    },
  ],
};
