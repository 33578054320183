import { gql } from '@apollo/client';

export const SET_TRANSACTION_REVIEWED = gql`
  mutation SetTransactionReviewed($transactionId: String!, $reviewed: Boolean!) {
    setTransactionReviewed(transactionId: $transactionId, reviewed: $reviewed) {
      id
      reviewed
    }
  }
`;

export interface SetTransactionReviewedInput {
  transactionId: string;
  reviewed: boolean;
}

export interface SetTransactionReviewedData {
  setTransactionReviewed: {
    id: string;
    reviewed: boolean;
    __typename: 'Transaction';
  };
}
