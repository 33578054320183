import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';

interface EmailFieldProps {
  isInvalid: boolean;
  value: string;
  fieldRef: React.RefObject<HTMLInputElement>;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onKeyPress: React.KeyboardEventHandler<HTMLInputElement>;
}

export const EmailField = ({
  value,
  isInvalid,
  fieldRef,
  onChange,
  onKeyPress,
}: EmailFieldProps) => {
  return (
    <FormControl id="email" isInvalid={isInvalid}>
      <FormLabel>Email</FormLabel>
      <Input
        ref={fieldRef}
        type="email"
        autoComplete="username"
        required
        enterKeyHint="next"
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
      <FormErrorMessage>{fieldRef.current?.validationMessage}</FormErrorMessage>
    </FormControl>
  );
};
