import { Flex, useDisclosure, Button, Text, Tag, IconButton } from '@chakra-ui/react';
import { useMutation, useQuery } from '@apollo/client';

import { CreateAutomationModal } from './CreateAutomationModal';
import {
  GET_AUTOMATIONS,
  type GetAutomationsData,
  GET_AUTOMATIONS_PLACEHOLDER,
} from 'src/graphql/GetAutomations';
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useState, useMemo } from 'react';
import { SharedTable } from 'src/components/shared/SharedTable';
import { capitalize } from '@flume-finance/common';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';

import { DeleteAutomation, type IDeleteAutomaton } from 'src/graphql/DeleteAutomation';
import { DeleteConfirmationAlert } from 'src/components/shared/DeleteConfirmationAlert';
import { CategoryTag } from 'src/components/shared/CategoryTag';
import { AppRoot } from 'src/components/shared/AppRoot';
import { EmptyListCta } from 'src/components/shared/EmptyListCta';
import RobotIcon from 'src/assets/icons/icons8-bot.svg?react';
import { useTranslation } from 'react-i18next';

type ColumnData = GetAutomationsData['automations'][0];

export function AutomationApp() {
  const { t } = useTranslation();
  const [automationIdForDeletion, setAutomationIdForDeletion] = useState<string | undefined>(
    undefined,
  );
  const [deleteAutomation] = useMutation<IDeleteAutomaton['output'], IDeleteAutomaton['input']>(
    DeleteAutomation.query,
    {
      update: DeleteAutomation.update,
      optimisticResponse: DeleteAutomation.optimisticResponse({
        automationId: automationIdForDeletion ?? '',
      }),
    },
  );

  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
  const columnHelper = createColumnHelper<ColumnData>();

  // biome-ignore lint/suspicious/noExplicitAny: Quirk of @tanstack/react-table and typescript
  const columns: ColumnDef<ColumnData, any>[] = useMemo(
    () => [
      columnHelper.accessor((a) => a.triggers[0], {
        id: 'trigger',
        header: 'Trigger',
        enableSorting: false,
        enableColumnFilter: false,
        meta: {
          flexGrow: 1,
          flexBasis: 200,
        },
        cell: (props) => {
          const trigger = props.row.original.triggers[0];
          return (
            <Flex alignItems="center" gap={2}>
              <Tag flexBasis="auto">
                <Text isTruncated>{capitalize(trigger.field)}</Text>
              </Tag>
              <Tag flexBasis="auto">
                <Text isTruncated>{trigger.matcher}</Text>
              </Tag>
              <Tag flex={1} maxW="fit-content">
                <Text fontFamily="monospace" isTruncated>
                  {trigger.value.toUpperCase()}
                </Text>
              </Tag>
            </Flex>
          );
        },
      }),
      columnHelper.accessor((a) => a.actions[0], {
        id: 'action',
        header: 'Action',
        enableSorting: false,
        enableColumnFilter: false,
        meta: {
          flexGrow: 1,
          flexBasis: 180,
        },
        cell: (props) => {
          const action = props.row.original.actions[0];
          return (
            <Flex alignItems="center">
              <Tag variant="subtle" py={1} px={2} gap={2} borderRightRadius={0}>
                <Text noOfLines={1}>{capitalize(action.field)}</Text>
              </Tag>
              <CategoryTag categoryId={action.value} hideLeftRadius={true} />
            </Flex>
          );
        },
      }),
      columnHelper.display({
        id: 'rowActions',
        meta: {
          flexBasis: '60px',
        },
        cell: (props) => {
          const automationId = props.row.original.id;
          return (
            <Flex alignItems="center">
              <IconButton
                colorScheme={'gray'}
                variant="ghost"
                size="sm"
                icon={<DeleteIcon />}
                aria-label={t('automations.table.deleteAriaLabel') ?? undefined}
                borderRadius={8}
                onClick={() => {
                  setAutomationIdForDeletion(automationId);
                  onAlertOpen();
                }}
              />
            </Flex>
          );
        },
      }),
    ],
    [onAlertOpen, columnHelper, t],
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { error, data } = useQuery<GetAutomationsData>(GET_AUTOMATIONS);

  const isInitialLoaded = data !== undefined;
  const automationsData = data?.automations;
  const automations: ColumnData[] = useMemo(() => {
    return isInitialLoaded ? automationsData || [] : GET_AUTOMATIONS_PLACEHOLDER.automations;
  }, [isInitialLoaded, automationsData]);

  return (
    <AppRoot noPadding={true}>
      <Flex flexDirection="column" flexGrow={1}>
        {/* HACK: Add buttons in the top right back to shared table. This broke when I moved the action row to the bottom middle when rows are selected */}
        <Button
          position={'absolute'}
          top={{ base: 2, md: 4 }}
          right={{ base: 2, md: 4 }}
          colorScheme={'gray'}
          variant={'outline'}
          boxShadow="base"
          leftIcon={<AddIcon w={3} h={3} />}
          onClick={onOpen}
          size="sm"
          justifySelf={'flex-end'}
          justifyContent="flex-end"
        >
          {t('automations.actionButtonText')}
        </Button>
        <SharedTable
          columns={columns}
          data={automations}
          fetchMore={() => {
            /* do nothing */
          }}
          hasMore={false}
          isInitialLoaded={isInitialLoaded}
          isLoading={false}
          error={error}
          onRowClick={(_entity: ColumnData) => {
            /* do nothing */
          }}
          showHeadingBreadcrumbs={true}
          resourceNamePlural={t('automations.table.resourceNamePlural')}
          emptyComponent={
            <EmptyListCta
              title={t('automations.emptyListCta.title')}
              description={t('automations.emptyListCta.description')}
              Icon={RobotIcon}
              buttonText={t('automations.emptyListCta.buttonText')}
              onClick={onOpen}
            />
          }
        />
        <DeleteConfirmationAlert
          isOpen={isAlertOpen}
          resource={t('automations.table.resourceName')}
          onClose={onAlertClose}
          onConfirm={() =>
            automationIdForDeletion &&
            deleteAutomation({ variables: { automationId: automationIdForDeletion } })
          }
        />
        <CreateAutomationModal isOpen={isOpen} onClose={onClose} />
      </Flex>
    </AppRoot>
  );
}
