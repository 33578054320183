import { gql } from '@apollo/client';
import type { GraphqlMutation } from 'src/util/graphql';

export interface ISetBulkReviewed {
  input: {
    bulkReviewInput: {
      transactionIds: string[];
      reviewed: boolean;
    };
  };
  output: {
    setBulkReviewed: {
      affectedTransactionIds: string[];
    };
  };
}

export const SetBulkReviewed: GraphqlMutation<
  ISetBulkReviewed,
  {
    affectedTransactionIds: string[];
  }
> = {
  query: gql`
    mutation SetBulkReviewed($bulkReviewInput: BulkReviewInput!) {
      setBulkReviewed(bulkReviewInput: $bulkReviewInput) {
        affectedTransactionIds
      }
    }
  `,
  optimisticResponse: ({ affectedTransactionIds }) => ({
    setBulkReviewed: {
      affectedTransactionIds,
    },
  }),
  update: (cache, { data }, { variables }) => {
    if (data === undefined || data == null || variables === undefined) {
      return;
    }

    // Update the reviewed in the cache of all affected transactions
    data.setBulkReviewed.affectedTransactionIds.map((tId) => {
      cache.modify({
        id: `Transaction:${tId}`,
        fields: {
          reviewed() {
            return variables.bulkReviewInput.reviewed;
          },
        },
      });
    });
  },
};
