import { Flex, Spacer } from '@chakra-ui/react';
import { EditCategoriesButton } from 'src/components/shared/buttons/EditCategoriesButton';
import { ActionButtons } from './ActionButtons';
import { CardStack } from './CardStack';
import { CategoryButtons } from './CategoryButtons';
import { type FunctionComponent, useEffect, useState, useCallback } from 'react';
import { HeadingWithMenu } from 'src/components/shared/HeadingWithMenu';

const NUM_CARDS = 3;
export type Action = 'skip' | 'undo' | 'review';
export type ExitAnimation = Action | 'none';

interface ReviewSectionProps<Entity extends { id: string }> {
  total: number;
  entities: Entity[];
  entityType: 'transactions' | 'automationSuggestions';
  isLoaded: boolean;
  updateEntity: (mode: Action, entityId: Entity['id'], categoryId?: string) => void;
  fetchMore: (offset: number, numCards: number) => void;
  Card: FunctionComponent<{
    entity: Entity;
    loading: boolean;
    index: number;
  }>;
  CompleteComponent: JSX.Element;
}

export const CategorizeSection = <Entity extends { id: string }>({
  entities,
  entityType,
  isLoaded,
  total,
  updateEntity,
  fetchMore,
  Card,
  CompleteComponent,
}: ReviewSectionProps<Entity>) => {
  const [offset, setOffset] = useState<number>(0);
  const [topOfStackId, setTopOfStackId] = useState<string | null>(null);
  const [exitAnimation, setExitAnimation] = useState<ExitAnimation>('none');

  const generateActionHandler = useCallback(
    (action: Action) => {
      return (categoryId?: string) => {
        // NOTE: Undo affects the previous entity where skip and review affect the current entity
        const entityId = action === 'undo' ? entities[offset - 1].id : entities[offset].id;

        updateEntity(action, entityId, categoryId);
        fetchMore(offset, NUM_CARDS);
        setExitAnimation(action);

        // Allow card to render with changes before starting exit animation
        setTimeout(() => {
          if (action === 'undo') {
            setOffset(offset - 1);
          } else {
            setOffset(offset + 1);
          }
        }, 0);
      };
    },
    [entities, offset, updateEntity, fetchMore],
  );

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // Prevent typing into input elements from triggering shortcuts
      if (e.target instanceof HTMLInputElement) {
        return;
      }

      if (isLoaded && !e.repeat) {
        if (e.code === 'ArrowLeft' && offset > 0) {
          generateActionHandler('undo')();
        } else if (e.code === 'ArrowRight') {
          generateActionHandler('skip')();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown, { passive: true });

    // Clean up the event handler on unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [offset, isLoaded, generateActionHandler]);

  // Reset offset when underlying entities change
  useEffect(() => {
    const nextTopOfStackId = entities?.[0]?.id;
    if (nextTopOfStackId === undefined || !isLoaded) {
      return;
    }

    if (!topOfStackId) {
      setTopOfStackId(nextTopOfStackId);
    } else if (topOfStackId !== nextTopOfStackId) {
      setExitAnimation('none');
      setTopOfStackId(nextTopOfStackId);
      setOffset(0);
    }
  }, [topOfStackId, entities[0], isLoaded]);

  const hideCards = offset >= total && isLoaded;

  return (
    <Flex
      flexDirection={'column'}
      maxW="100ch"
      flexGrow={1}
      w="full"
      align="center"
      gap={{ base: 2, md: 6 }}
    >
      <Flex justify={'space-between'} width="full">
        <HeadingWithMenu />
        <EditCategoriesButton />
      </Flex>
      <Flex flexDir={'column'} flex={1} hidden={!hideCards}>
        <Spacer flexBasis={'30%'} />
        {CompleteComponent}
        <Spacer flexBasis={'70%'} />
      </Flex>
      <Flex flexDir={'column'} align="center" hidden={hideCards} gap={{ base: 2, md: 6 }} flex={1}>
        <CardStack
          entities={entities}
          entityType={entityType}
          loading={!isLoaded}
          total={total}
          offset={offset}
          exitAnimation={exitAnimation}
          numCards={NUM_CARDS}
          Content={Card}
        />
        <CategoryButtons otherLoaded={isLoaded} updateCategory={generateActionHandler('review')} />
        <ActionButtons
          loading={!isLoaded}
          setExitAnimation={setExitAnimation}
          disableUndo={offset === 0}
          onClick={generateActionHandler}
        />
      </Flex>
    </Flex>
  );
};
