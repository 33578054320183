import { Button, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import ErrorIcon from 'src/assets/icons/icons8-error.svg?react';

interface ErrorProps {
  message: string;
  hidden?: boolean;
  refetch?: () => void;
}

function ErrorWithRefetch({ refetch, message, hidden = false }: ErrorProps) {
  const { t } = useTranslation();
  const size = 60;
  const onClick = () => {
    refetch?.();
  };

  return (
    <Flex direction="column" align="center" gap={6} hidden={hidden}>
      <Flex flexDir={'column'} align="center">
        <ErrorIcon width={size} height={size} style={{ marginBottom: '0.5rem' }} />
        <Text fontSize="lg">{message}</Text>
      </Flex>
      <Button colorScheme={'gray'} hidden={refetch === undefined} onClick={onClick}>
        {t('common.error.retryButtonText')}
      </Button>
    </Flex>
  );
}

export { ErrorWithRefetch };
