import { gql } from '@apollo/client';

export const DELETE_CATEGORY = gql`
  mutation DeleteCategory($categoryId: String!) {
    deleteCategory(categoryId: $categoryId) {
      id
    }
  }
`;

export interface DeleteCategoryData {
  deleteCategory: {
    id: string;
  };
}

export interface DeleteCategoryInput {
  categoryId: string;
}
