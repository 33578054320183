import { gql } from 'src/graphql/__generated__/gql';

export const GET_NET_WORTH_HISTORY = gql(/* GraphQL */ `
  query GetNetWorthHistory(
    $startDate: DateTime!
    $endDate: DateTime!
    $interval: AssetAggregationInterval!
  ) {
    netWorthHistory(startDate: $startDate, endDate: $endDate, interval: $interval) {
      netWorth
      percentChange
      history {
        date
        value
      }
      xLabels
      xLabelFormat
    }
  }
`);
