import { Icon, IconButton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import DotMenuIcon from 'src/assets/icons/icons8-menu-vertical.svg?react';

interface SettingsIconButtonProps {
  onClick: () => void;
  isDisabled: boolean;
}

export const SettingsIconButton = ({ onClick, isDisabled }: SettingsIconButtonProps) => {
  const { t } = useTranslation();

  return (
    <IconButton
      variant={'ghost'}
      size="sm"
      colorScheme={'gray'}
      icon={<Icon as={DotMenuIcon} fill={'iconColor'} w={'1.125rem'} h={'1.125rem'} />}
      aria-label={t('accounts.settingsButtonLabel')}
      onClick={(e) => {
        onClick();
        e.stopPropagation();
      }}
      isDisabled={isDisabled}
    />
  );
};
