import { gql } from '@apollo/client';

export const EXCHANGE_PUBLIC_TOKEN = gql`
  mutation ExchangePublicToken($publicToken: String!) {
    exchangePublicToken(publicToken: $publicToken) {
      itemId
    }
  }
`;

export interface ExchangePublicTokenInput {
  publicToken: string;
}

export interface ExchangePublicTokenData {
  __typename: 'Mutation';
  exchangePublicToken: {
    itemId: string;
  };
}
