import { gql } from '@apollo/client';

export const SET_TRANSACTION_NOTES = gql`
  mutation SetTransactionNotes($transactionId: String!, $notes: String!) {
    setTransactionNotes(transactionId: $transactionId, notes: $notes) {
      id
      notes
    }
  }
`;

export interface SetTransactionNotesInput {
  transactionId: string;
  notes: string;
}

export interface SetTransactionNotesData {
  setTransactionNotes: {
    id: string;
    notes: string;
    __typename: 'Transaction';
  };
}
