import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { IconButton, Spinner, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import type { Item } from 'src/components/overview/AccountSection';

import { utc, duration } from 'src/util/dayjs';
import { ExternalPlatform } from 'src/graphql/__generated__/graphql';

interface ItemStatusProps {
  item: Item;
  onReauthorizeClick: (item: Item) => void;
}

export const ItemStatus = ({ item, onReauthorizeClick }: ItemStatusProps) => {
  const { t } = useTranslation();
  const ICON_SIZE = 5;
  const COLOR = 'iconColor';

  if (item.externalPlatformType === ExternalPlatform.Manual) {
    return null;
  }

  if (item.expiresAt) {
    return (
      <Tooltip label={t('accounts.connectionNeedsAttention')}>
        <IconButton
          variant={'ghost'}
          colorScheme="gray"
          size="sm"
          icon={
            <WarningIcon
              color={'errorColor'}
              w={ICON_SIZE}
              h={ICON_SIZE}
              display={utc(item.expiresAt).isSameOrBefore(utc()) ? undefined : 'none'}
              onClick={(e) => {
                onReauthorizeClick(item);
                e.stopPropagation();
              }}
            />
          }
          aria-label={t('accounts.connectionNeedsAttention')}
        />
      </Tooltip>
    );
  }

  if (item.lastSyncedAt) {
    const minutesAgo = utc(item.lastSyncedAt).diff(utc(), 'minutes');
    return (
      <Tooltip
        label={t('accounts.connectionSynced', {
          durationAgo: duration({ minutes: minutesAgo }).humanize(true),
        })}
      >
        <IconButton
          variant={'ghost'}
          colorScheme="gray"
          size="sm"
          icon={<CheckCircleIcon w={ICON_SIZE} h={ICON_SIZE} color={COLOR} />}
          onClick={(e) => {
            onReauthorizeClick(item);
            e.stopPropagation();
          }}
          aria-label={t('accounts.connectionSynced')}
        />
      </Tooltip>
    );
  }

  return <Spinner size="sm" color={COLOR} speed="1s" emptyColor="hoverBg" />;
};
