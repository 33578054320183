// TODO: Move format currency to common and add support for multi-currency
export const formatCurrency = ({
  cents,
  currencyCode,
  notation,
  hideCents = false,
}: {
  cents: number | bigint;
  currencyCode: string;
  hideCents?: boolean;
  notation?: Intl.NumberFormatOptions['notation'];
}) => {
  return (Number(cents) * 1e-2).toLocaleString(navigator.language ?? 'en-US', {
    style: 'currency',
    notation,
    currency: currencyCode,
    ...(hideCents && {
      maximumFractionDigits: 0,
    }),
    ...(notation === 'compact' && {
      minimumSignificantDigits: 2,
      maximumSignificantDigits: 3,
    }),
  });
};

// TODO: Convert to a currency agnostic parser
export const parseAmount = (amount: string): number => {
  const charsToRemove = new RegExp(/\$|-|\(|\)|,|\+|\s/, 'g');
  const negative = ['-', '('].some((char) => amount.includes(char));

  const cleanAmount = amount.replaceAll(charsToRemove, '');
  const val = Math.round(Number(cleanAmount) / 1e-2);

  return negative ? val * -1 : val;
};

export function formatPercentage(value: number) {
  if (Number.isNaN(value)) {
    return '0%';
  }

  return `${(value * 100).toFixed(1)}%`;
}

export function sum(arr: number[]): number {
  return arr.reduce((acc, n) => acc + n, 0);
}

export function sumObj<T>(arr: T[], mapFn: (x: T) => number): number {
  return arr.reduce((acc, n) => acc + mapFn(n), 0);
}

function debounce<InputArgs = Record<string, string | number>>(
  func: (args: InputArgs) => void,
  wait: number,
  immediate?: boolean,
) {
  let timeout: number;

  return (innerArgs: InputArgs) => {
    const later = () => {
      timeout = -1;
      if (!immediate) func(innerArgs);
    };
    const callNow = immediate && !timeout;

    window.clearTimeout(timeout);
    timeout = window.setTimeout(later, wait);
    if (callNow) func(innerArgs);
  };
}

export { debounce };
