import { Flex } from '@chakra-ui/react';
import { AppRoot } from 'src/components/shared/AppRoot';
import { HeadingWithMenu } from 'src/components/shared/HeadingWithMenu';
import { AccountSection } from './AccountSection';
import { ActionItemsSection } from './ActionItemsSection';
import { NetWorthChart } from './NetWorthChart';

export function OverviewApp() {
  return (
    <AppRoot>
      <Flex flexDirection={'column'} gap={4} flexGrow={1}>
        <Flex
          mt={{ base: -2, md: -4 }}
          pt={{ base: 2, md: 4 }}
          top={{ base: -2, md: -4 }}
          bg="mainBg"
          zIndex={'sticky'}
          position="sticky"
        >
          <HeadingWithMenu />
        </Flex>
        <NetWorthChart />

        <ActionItemsSection />
        <AccountSection />
      </Flex>
    </AppRoot>
  );
}
