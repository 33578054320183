import {
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  Tabs,
  TabPanel,
  TabList,
  TabPanels,
  type useDisclosure,
  Flex,
} from '@chakra-ui/react';
import { type Dispatch, type SetStateAction, useState } from 'react';
import { utc } from 'src/util/dayjs';
import { ReconnectPlaidButton } from 'src/components/account/ReconnectPlaidButton';
import type { Item } from './AccountSection';
import { ExternalPlatform } from 'src/graphql/__generated__/graphql';

export const ReauthorizePanel = ({
  item,
  onClose,
  setPlaidLinkOpen,
}: {
  item: Item;
  onClose: EditItemModalProps['onClose'];
  setPlaidLinkOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const REAUTHORIZE_TEXT =
    'Our connection with your account has expired. This can happen when your password changes, when MFA requirements change, or when your login becomes locked.';
  const DEFAULT_TEXT =
    'If you want to add or remove accounts, you can update your connection below.';
  const descriptionText = utc(item.expiresAt).isSameOrBefore(utc())
    ? REAUTHORIZE_TEXT
    : DEFAULT_TEXT;

  return (
    <Flex flexDir={'column'} gap={6}>
      <Text>{descriptionText}</Text>
      <ReconnectPlaidButton
        onClose={onClose}
        itemId={item.id}
        setPlaidLinkOpen={setPlaidLinkOpen}
      />
    </Flex>
  );
};

interface EditItemModalProps {
  isOpen: boolean;
  onClose: ReturnType<typeof useDisclosure>['onClose'];
  item: Item;
}
export const EditItemModal = ({ isOpen, onClose, item }: EditItemModalProps) => {
  const [plaidLinkOpen, setPlaidLinkOpen] = useState<boolean>(false);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" trapFocus={false}>
      <ModalOverlay hidden={plaidLinkOpen} />
      <ModalContent hidden={plaidLinkOpen}>
        <ModalHeader>
          <Text>{item.institutionName}</Text>
        </ModalHeader>
        <ModalBody>
          <Tabs variant={'enclosed'}>
            <TabList>
              <Tab hidden={item === null || item.externalPlatformType !== ExternalPlatform.Plaid}>
                Reauthorize
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <ReauthorizePanel
                  item={item}
                  onClose={onClose}
                  setPlaidLinkOpen={setPlaidLinkOpen}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};
