import { useReactiveVar } from '@apollo/client';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Icon,
  IconButton,
} from '@chakra-ui/react';
import { capitalize } from '@flume-finance/common';
import { t } from 'i18next';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from 'src/assets/icons/navbar/icons8-menu-rounded.svg?react';
import { sidebarExpanded } from 'src/cache';

interface HeadingWithMenuProps {
  showBreadcrumbs?: boolean;
}

export const HeadingWithMenu = ({ showBreadcrumbs = true }: HeadingWithMenuProps) => {
  const expandedValue = useReactiveVar(sidebarExpanded);
  const location = useLocation();

  const breadCrumbs: { title: string; path: string }[] = useMemo(() => {
    const sections = location.pathname.split('/');
    const crumbs = [];
    let path = sections[0];

    for (const section of sections.slice(1)) {
      path = `${path}/${section}`;
      crumbs.push({ title: capitalize(section), path });
    }

    return crumbs;
  }, [location.pathname]);

  return (
    <Flex
      alignItems={'center'}
      gap={1}
      position="sticky"
      top={0}
      bg="mainBg"
      zIndex={'sticky'}
      display={{ base: 'inherit', md: showBreadcrumbs ? 'inherit' : 'none' }}
    >
      <IconButton
        colorScheme={'gray'}
        icon={<Icon as={MenuIcon} fill={'iconColor'} width={'1.125rem'} height={'1.125rem'} />}
        aria-label={t('navigation.expandButtonLabel')}
        variant="ghost"
        borderRadius={'lg'}
        type="button"
        size={'sm'}
        _hover={{ bg: 'hoverBg' }}
        onClick={() => {
          const nextExpandedValue = !expandedValue;
          sidebarExpanded(nextExpandedValue);
          localStorage.setItem('sidebarExpanded', nextExpandedValue.toString());
        }}
        display={{ base: 'inherit', md: 'none' }}
      />
      <Breadcrumb hidden={!showBreadcrumbs} fontSize={{ base: 'sm', md: '3xl' }}>
        {breadCrumbs.map((bc, i) => {
          const current = i === breadCrumbs.length - 1;
          return (
            <BreadcrumbItem key={bc.path} isCurrentPage={current}>
              <BreadcrumbLink
                as={Link}
                to={bc.path}
                fontWeight={current ? 'bold' : undefined}
                tabIndex={-1}
              >
                {bc.title}
              </BreadcrumbLink>
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    </Flex>
  );
};
