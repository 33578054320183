import { gql } from '@apollo/client';

export const EDIT_CATEGORY = gql`
  mutation EditCategory($id: String!, $name: String!, $color: String!) {
    editCategory(id: $id, name: $name, color: $color) {
      id
      name
      color
    }
  }
`;

export interface EditCategoryInput {
  id: string;
  name: string;
  color: string;
}

export interface EditCategoryData {
  __typename: 'Mutation';
  editCategory: {
    id: string;
    name: string;
    color: string;
    __typename: 'Category';
  };
}
