import { gql } from 'src/graphql/__generated__/gql';

export const FIND_OR_CREATE_ACCOUNTS_BY_NAME_MUTATION = gql(/* GraphQL */ `
mutation FindOrCreateAccountsByName($createAccountInputs: [CreateManyManualAccountArgs!]!) {
  findOrCreateAccounts(createAccountInputs: $createAccountInputs) {
    id
    nickname
    type
  }
}
`);
