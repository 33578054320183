import { gql } from 'src/graphql/__generated__/gql';

export const GET_CATEGORY_INSIGHT = gql(/* GraphQL */ `
  query GetCategoryAggregation(
    $startDate: DateTime!
    $endDate: DateTime!
    $interval: AggregationInterval!
  ) {
    categoryAggregation(startDate: $startDate, endDate: $endDate, interval: $interval) {
      xLabels
      startDate
      endDate
      aggregations {
        category {
          id
          name
          color
        }
        amounts
      }
    }
  }
`);
