import { gql } from '@apollo/client';
import type { GraphqlMutation } from 'src/util/graphql';

export interface IRegisterNotificationSubscription {
  input: {
    endpoint: string;
    p256dh: string;
    auth: string;
  };
  output: {
    registerSubscription: {
      success: boolean;
    };
  };
}

export const RegisterNotificationSubscription: GraphqlMutation<IRegisterNotificationSubscription> =
  {
    query: gql`
      mutation RegisterSubscription($endpoint: String!, $p256dh: String!, $auth: String!) {
        registerNotificationSubscription(endpoint: $endpoint, p256dh: $p256dh, auth: $auth) {
          success
        }
      }
    `,
    optimisticResponse: (_args) => {
      return { registerSubscription: { success: true } };
    },
  };
