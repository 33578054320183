import { useMutation, useQuery } from '@apollo/client';
import { DeleteIcon } from '@chakra-ui/icons';
import { Flex, IconButton, Skeleton, Text } from '@chakra-ui/react';
import {
  DELETE_TRANSFER,
  type DeleteTransferData,
  type DeleteTransferInput,
} from 'src/graphql/DeleteTransfer';
import { GET_TRANSACTION, GET_TRANSACTION_PLACEHOLDER } from 'src/graphql/GetTransaction';
import type { GetTransactionQuery } from 'src/graphql/__generated__/graphql';

interface TransferTransactionTagProps {
  linkedTransactionId: GetTransactionQuery['transaction']['id'];
  transfer: NonNullable<GetTransactionQuery['transaction']['linkedTransfer']>;
}

export const TransferTransactionTag = ({
  linkedTransactionId,
  transfer,
}: TransferTransactionTagProps) => {
  const { data: transactionData, loading: transactionLoading } = useQuery(GET_TRANSACTION, {
    variables: {
      transactionId: linkedTransactionId,
    },
  });

  const [deleteTransfer] = useMutation<DeleteTransferData, DeleteTransferInput>(DELETE_TRANSFER, {
    update(cache, { data }) {
      if (data === undefined || data == null) {
        return;
      }

      // Update the linked transfer in the cache of all affected transactions
      [transfer.inflowTransaction.id, transfer.outflowTransaction.id].map((tId) => {
        cache.modify({
          id: `Transaction:${tId}`,
          fields: {
            linkedTransfer() {
              return null;
            },
          },
        });
      });
    },
    optimisticResponse: {
      deleteTransfer: {
        id: transfer.id,
      },
    },
  });

  const linkedTransaction =
    transactionData === undefined
      ? GET_TRANSACTION_PLACEHOLDER.transaction
      : transactionData.transaction;

  return (
    <Skeleton isLoaded={!transactionLoading}>
      <Flex
        alignItems={'center'}
        py={2}
        px={4}
        gap={4}
        width={'full'}
        borderWidth={1}
        borderColor="borderColor"
        borderRadius={'lg'}
      >
        <Flex flexDir={'column'} gap={1} flex={1}>
          <Text noOfLines={1}>{linkedTransaction.payee}</Text>
          <Flex gap={1}>
            <Text noOfLines={1} color="gray.500">
              {linkedTransaction.date.toLocaleDateString()}
            </Text>
            <Text color="gray.500">•</Text>
            <Text noOfLines={1} color="gray.500">
              {linkedTransaction.account.nickname}
            </Text>
          </Flex>
        </Flex>
        <IconButton
          colorScheme={'gray'}
          aria-label="Unlink Transaction"
          icon={<DeleteIcon />}
          onClick={() => {
            deleteTransfer({ variables: { transferId: transfer.id } });
          }}
        />
      </Flex>
    </Skeleton>
  );
};
