import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      name
      email
      isEmailVerified
    }
  }
`;

export interface LoginInput {
  email: string;
  password: string;
}

export interface LoginPayload {
  login: {
    id: string;
    name: string;
    email: string;
    isEmailVerified: boolean;
  };
}
