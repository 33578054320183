//  import { useMutation } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { Flex, Button } from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberField } from 'src/components/overview/createAccountFlow/CreateEditAccountForm';
import { CreateEmailAccount, type ICreateEmailAccount } from 'src/graphql/CreateEmailAccount';

interface SetupEmailAccountProps {
  next: (accountId: string) => void;
}

export const SetupEmailAccount = ({ next }: SetupEmailAccountProps) => {
  const { t } = useTranslation();

  const formRef = useRef<HTMLFormElement>(null);

  const [balance, setBalance] = useState<bigint>(0n);
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState<boolean>(false);

  const [createEmailAccount, { loading }] = useMutation<
    ICreateEmailAccount['output'],
    ICreateEmailAccount['input']
  >(CreateEmailAccount.query, {
    optimisticResponse: CreateEmailAccount.optimisticResponse({
      id: crypto.randomUUID(),
      nickname: 'Venmo',
    }),
    update: CreateEmailAccount.update,
  });

  const submit = () => {
    setHasBeenSubmitted(true);

    if (formRef.current?.checkValidity()) {
      createEmailAccount({
        variables: {
          createAccountArgs: {
            nickname: 'Venmo',
            balanceOffset: balance,
          },
        },
      }).then(({ data }) => {
        const account = data?.createEmailAccount;
        if (account === undefined) {
          throw new Error('Failed to create Venmo account');
        }

        next(account.id);
      });
    }
  };

  return (
    <form ref={formRef}>
      <Flex gap={4} flexDirection="column">
        <NumberField
          fieldId="currentBalance"
          label={'Current Balance'}
          helperText={t('accounts.createAutomaticModal.venmo.balanceHelperText')}
          value={balance.toString()}
          currencyCode={'USD'}
          onChange={(v) => setBalance(BigInt(v))}
          hasBeenSubmitted={hasBeenSubmitted}
          step={10}
        />
        <Button
          alignSelf={'flex-end'}
          onClick={submit}
          isDisabled={hasBeenSubmitted && !formRef.current?.checkValidity()}
          isLoading={loading}
        >
          Create
        </Button>
      </Flex>
    </form>
  );
};
