import { gql } from 'src/graphql/__generated__/gql';

export const GET_ACCOUNT = gql(/* GraphQL */ `
  query GetAccount($id: ID!) {
    account(id: $id) {
      id
      nickname
      type
      balance
      accountNumber
      syncMode
      item {
        id
        externalPlatformType
        lastSyncedAt
        expiresAt
        institutionName
      }
    }
  }
`);
