import { gql } from 'src/graphql/__generated__/gql';
import type { GraphqlMutation } from 'src/util/graphql';
import type {
  CreateAssetValueMutation,
  CreateAssetValueMutationVariables,
} from 'src/graphql/__generated__/graphql';
import type { Reference } from '@apollo/client';

export interface ICreateAssetValue {
  input: CreateAssetValueMutationVariables;
  output: CreateAssetValueMutation;
}

export const CreateAssetValue: GraphqlMutation<
  ICreateAssetValue,
  CreateAssetValueMutation['createAssetValue']
> = {
  query: gql(/* GraphQL */ `
    mutation CreateAssetValue($assetId: ID!, $date: DateTime!, $balance: Bigint!) {
      createAssetValue(assetId: $assetId, date: $date, balance: $balance) {
        id
        date
        value
      }
    }
  `),
  optimisticResponse: ({ id, date, value }) => ({
    createAssetValue: {
      id,
      date,
      value,
      __typename: 'AssetValue',
    },
  }),
  update: (cache, { data }, { variables }) => {
    if (data === undefined || data == null || variables === undefined) {
      return;
    }

    cache.modify<{ assetValues: Reference[] }>({
      id: cache.identify({ __typename: 'Asset', id: variables.assetId }),
      fields: {
        assetValues(existingAssetValueRefs, { toReference, readField }) {
          const avRef = toReference(data.createAssetValue, true);

          if (avRef === undefined) {
            return existingAssetValueRefs;
          }

          return [...existingAssetValueRefs, avRef].sort((aRef, bRef) => {
            const a: Date = new Date(readField<string>('date', aRef) ?? '');
            const b: Date = new Date(readField<string>('date', bRef) ?? '');

            return b.getTime() - a.getTime();
          });
        },
      },
    });

    cache.evict({ id: 'ROOT_QUERY', fieldName: 'overviewElements' });
    cache.evict({ id: 'ROOT_QUERY', fieldName: 'netWorthHistory' });
  },
};
