import { Flex } from '@chakra-ui/react';
import { AppRoot } from 'src/components/shared/AppRoot';
import { BudgetList } from './BudgetList';
import { MonthPicker } from 'src/components/shared/MonthPicker';
import { EditCategoriesButton } from 'src/components/shared/buttons/EditCategoriesButton';
import { HeadingWithMenu } from 'src/components/shared/HeadingWithMenu';

export function BudgetApp() {
  return (
    <AppRoot>
      <Flex flexDirection="column" flexGrow={1} maxW="100ch" gap={{ base: 2, md: 4 }}>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems={'flex-start'} gap={1}>
            <HeadingWithMenu showBreadcrumbs={false} />
            <MonthPicker />
          </Flex>

          <EditCategoriesButton />
        </Flex>
        <BudgetList />
      </Flex>
    </AppRoot>
  );
}
