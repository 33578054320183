export enum ActionField {
  CATEGORY = 'CATEGORY',
}

export enum TriggerField {
  PAYEE = 'PAYEE',
  MERCHANT = 'MERCHANT',
}

export enum TriggerFieldMatcher {
  CONTAINS = 'CONTAINS',
  STARTS_WITH = 'STARTS_WITH',
  ENDS_WITH = 'ENDS_WITH',
  EQUALS = 'EQUALS',
}
