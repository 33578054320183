import { Button, Container, Flex, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export function NotFound() {
  return (
    <Flex gap={4} mt={'10rem'}>
      <Container
        p={10}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Text fontSize="8rem">🛋️</Text>
        <Text fontSize="xl">We must have misplaced this page somewhere in the couch.</Text>
        <Link to="/">
          <Button type="button" mt={5}>
            Take me home
          </Button>
        </Link>
      </Container>
    </Flex>
  );
}
