import { gql } from 'src/graphql/__generated__/gql';

export const GET_POTENTIAL_TRANSFERS = gql(/* GraphQL */ `
  query GetPotentialTransfers($transactionId: String!) {
    potentialTransfers(transactionId: $transactionId) {
      id
      date
      payee
      account {
        nickname
      }
    }
  }
`);
