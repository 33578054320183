import { Select, Skeleton } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';

// import { ACCOUNT_ID_PARAM, selectedAccountId } from 'src/cache';

import { GET_ACCOUNTS } from 'src/graphql/GetAccounts';
// import { useSearchParams } from 'react-router-dom';
// import { useEffect } from 'react';
import type { FilterComponentProps } from 'src/@types/react-table';
import { useTranslation } from 'react-i18next';
import type { GetAccountsQuery } from 'src/graphql/__generated__/graphql';

const ALL_ACCOUNTS = 'all';

function AccountsSelect({ value, onChange }: FilterComponentProps<string>) {
  const { t } = useTranslation();
  const { loading, data } = useQuery<GetAccountsQuery>(GET_ACCOUNTS);
  // const [searchParams, setSearchParams] = useSearchParams();
  const accounts = loading || data === undefined ? [] : data.accounts;

  // useEffect(() => {
  //   selectedAccountId(searchParams.get(ACCOUNT_ID_PARAM) ?? undefined);
  // }, [searchParams.get(ACCOUNT_ID_PARAM)]);

  return (
    <Skeleton isLoaded={!loading}>
      <Select
        variant={'outline'}
        size="sm"
        borderRadius={6}
        value={value ?? ALL_ACCOUNTS}
        noOfLines={1}
        onChange={(e) => {
          const accountId = e.target.value as string;
          const nextAccountId = accountId === ALL_ACCOUNTS ? undefined : accountId;

          onChange(nextAccountId);

          //TODO: Move url serialization into SharedTable
          // if (nextAccountId !== undefined) {
          //   searchParams.set(ACCOUNT_ID_PARAM, nextAccountId);
          //   setSearchParams(searchParams);
          // } else {
          //   searchParams.delete(ACCOUNT_ID_PARAM);
          //   setSearchParams(searchParams);
          // }
        }}
      >
        <option key={'all-accounts'} value={ALL_ACCOUNTS} label="All Accounts">
          {t('accounts.select.allAccountsOption')}
        </option>
        {accounts.map((account) => (
          <option key={account.id} value={account.id} label={`${account.nickname}`}>
            {`${account.nickname}`}
          </option>
        ))}
      </Select>
    </Skeleton>
  );
}

export { AccountsSelect };
