import { useMutation } from '@apollo/client';
import { Button, Flex, FormControl, FormHelperText, SimpleGrid } from '@chakra-ui/react';
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlaidLinkButton } from 'src/components/plaid/PlaidLinkButton';
import { PLAID_LINK_TOKEN_KEY } from 'src/components/plaid/PlaidOauthRedirect';
import { DividerWithText } from 'src/components/shared/DividerWithText';
import {
  type CreatePlaidLinkTokenData,
  CREATE_PLAID_LINK_TOKEN,
} from 'src/graphql/CreatePlaidLinkToken';

interface SetupAutomaticAccountProps {
  setPlaidLinkOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
}

export const CreateAutomaticAccount = ({
  setPlaidLinkOpen,
  onClose,
}: SetupAutomaticAccountProps) => {
  const { t } = useTranslation();
  const [createLinkToken] = useMutation<CreatePlaidLinkTokenData>(CREATE_PLAID_LINK_TOKEN);

  const [linkToken, setLinkToken] = useState<string | undefined>(undefined);
  const [plaidError, setPlaidError] = useState<boolean>(false);

  useEffect(() => {
    createLinkToken()
      .then(({ data }) => {
        if (data) {
          setLinkToken(data.createPlaidLinkToken.linkToken);
          localStorage.setItem(PLAID_LINK_TOKEN_KEY, data.createPlaidLinkToken.linkToken);
        }
      })
      .catch((_err) => {
        setPlaidError(true);
      });
  }, [createLinkToken]);

  return (
    <Flex direction="column" alignItems="center" justifyContent={'center'} gap={4}>
      <FormControl display="flex" flexDir="column" alignItems={'center'}>
        <PlaidLinkButton
          linkToken={linkToken ?? null}
          isError={plaidError}
          onClose={onClose}
          setPlaidLinkOpen={setPlaidLinkOpen}
        />
        <FormHelperText>{t('accounts.createAutomaticModal.plaidHelperText')}</FormHelperText>
      </FormControl>
      <DividerWithText maxW={200}>{t('accounts.createAutomaticModal.or')}</DividerWithText>
      <FormControl display="flex" flexDir="column" alignItems={'center'} maxW={'20rem'}>
        <SimpleGrid gap={4} columns={{ sm: 1, md: 2 }} width={'20rem'}>
          <Button
            colorScheme={'gray'}
            variant="outline"
            aria-label={t('accounts.createAutomaticModal.splitwise.ariaLabel') ?? undefined}
            onClick={() => {
              window.location.href = `${import.meta.env.VITE_API_SERVER_BASE_URL}/splitwise/oauth`;
            }}
          >
            {t('accounts.createAutomaticModal.splitwise.buttonText')}
          </Button>
        </SimpleGrid>
        <FormHelperText textAlign={'center'}>
          {t('accounts.createAutomaticModal.directIntegrationHelperText')}
        </FormHelperText>
      </FormControl>
    </Flex>
  );
};
