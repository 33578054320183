import { gql } from '@apollo/client';

export const GET_SPLIT_USER = gql`
  query GetSplitUser($username: String!) {
    getSplitUser(username: $username) {
      name
      username
      imageUrl
    }
  }
`;

export interface GetSplitUserInput {
  username: string;
}

export interface GetSplitUserData {
  getSplitUser: {
    name: string;
    username: string;
    imageUrl: string | null;
  } | null;
}
