import { useMutation } from '@apollo/client';
import { MdOutlineMarkEmailRead, MdOutlineMarkEmailUnread } from 'react-icons/md';
import { Button, Flex, Icon, Text } from '@chakra-ui/react';
import type { RowSelectionState } from '@tanstack/react-table';
import { type ISetBulkReviewed, SetBulkReviewed } from 'src/graphql/SetBulkReviewed';
import type { GetTransactionsQuery } from 'src/graphql/__generated__/graphql';

interface ReviewButtonProps {
  transactions: BulkReviewActionsProps['data'];
  selectedTransactionIds: string[];
  unselectAll: () => void;
}

const ReviewButton = ({ transactions, selectedTransactionIds, unselectAll }: ReviewButtonProps) => {
  const [setBulkReviewed] = useMutation<ISetBulkReviewed['output'], ISetBulkReviewed['input']>(
    SetBulkReviewed.query,
    {
      optimisticResponse: SetBulkReviewed.optimisticResponse({
        affectedTransactionIds: selectedTransactionIds,
      }),
      update: SetBulkReviewed.update,
    },
  );

  const anyNeedReview = transactions
    .filter((t) => selectedTransactionIds.includes(t.id))
    .some((t) => !t.reviewed);

  return anyNeedReview ? (
    <Button
      colorScheme={'gray'}
      variant="outline"
      size="xs"
      leftIcon={<Icon as={MdOutlineMarkEmailRead} width={4} height={4} />}
      onClick={() => {
        setBulkReviewed({
          variables: {
            bulkReviewInput: { transactionIds: selectedTransactionIds, reviewed: true },
          },
        });
        unselectAll();
      }}
    >
      {'Mark as Reviewed'}
    </Button>
  ) : (
    <Button
      colorScheme={'gray'}
      variant="outline"
      size="xs"
      leftIcon={<Icon as={MdOutlineMarkEmailUnread} width={4} height={4} />}
      onClick={() => {
        setBulkReviewed({
          variables: {
            bulkReviewInput: { transactionIds: selectedTransactionIds, reviewed: false },
          },
        });
        unselectAll();
      }}
    >
      {'Mark as Needs Review'}
    </Button>
  );
};

interface BulkReviewActionsProps {
  rowSelection: RowSelectionState | undefined;
  data: GetTransactionsQuery['transactions']['transactions'];
  unselectAll: () => void;
}

export const BulkReviewActions = ({ rowSelection, data, unselectAll }: BulkReviewActionsProps) => {
  const selectedTransactionIds = Object.keys(rowSelection ?? {});

  return (
    <Flex gap={2} hidden={selectedTransactionIds.length === 0} alignItems="center">
      <Text fontSize={'xs'} textColor="subtleText" fontWeight={'bold'}>
        {selectedTransactionIds.length} selected
      </Text>
      <ReviewButton
        transactions={data}
        selectedTransactionIds={selectedTransactionIds}
        unselectAll={unselectAll}
      />
    </Flex>
  );
};
