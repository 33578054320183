import { useCallback, useEffect, useState } from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import { FormControl, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { debounce } from 'src/util';

interface SearchProps {
  value: string | null;
  onChange: (q: string | null) => void;
}

export const Search = ({ value, onChange }: SearchProps) => {
  const [filter, setFilter] = useState<string | null>(value);

  const debounceCb = useCallback(
    debounce<{ searchQuery: string | null }>(({ searchQuery }) => {
      onChange(searchQuery);

      // if (searchQuery.length > 0) {
      //   searchParams.set(TRANSACTION_PAYEE_SEARCH_PARAM, searchQuery);
      //   setSearchParams(searchParams);
      // } else {
      //   searchParams.delete(TRANSACTION_PAYEE_SEARCH_PARAM);
      //   setSearchParams(searchParams);
      // }
    }, 600),
    [],
  );

  useEffect(() => debounceCb({ searchQuery: filter }), [debounceCb, filter]);

  // useEffect(() => {
  //   payeeFilter(searchParams.get(TRANSACTION_PAYEE_SEARCH_PARAM) ?? '');
  //   setFilter(searchParams.get(TRANSACTION_PAYEE_SEARCH_PARAM) ?? '');
  // }, [searchParams.get(TRANSACTION_PAYEE_SEARCH_PARAM)]);

  return (
    <FormControl id="payee" maxW={300} flex={1}>
      <InputGroup size="sm">
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="iconColor" />
        </InputLeftElement>
        <Input
          type="search"
          variant="filled"
          borderRadius={6}
          placeholder="Search"
          value={filter ?? ''}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          onChange={(e) => {
            setFilter(e.target.value);
          }}
        />
      </InputGroup>
    </FormControl>
  );
};
