import { Link as RouterLink } from 'react-router-dom';
import {
  FormLabel,
  FormControl,
  Input,
  FormErrorMessage,
  Button,
  InputGroup,
  InputRightElement,
  Flex,
  Link,
  Box,
} from '@chakra-ui/react';
import { useState } from 'react';

interface PasswordFieldProps {
  isInvalid: boolean;
  value: string;
  fieldRef: React.RefObject<HTMLInputElement>;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onKeyPress: React.KeyboardEventHandler<HTMLInputElement>;
  autoComplete: 'new-password' | 'current-password';
  showForgotPassword?: boolean;
}

export const PasswordField = ({
  value,
  isInvalid,
  fieldRef,
  onChange,
  onKeyPress,
  autoComplete,
  showForgotPassword = false,
}: PasswordFieldProps) => {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <FormControl id="password" isInvalid={isInvalid}>
      <Flex justifyContent={'space-between'} alignItems="vertical">
        <FormLabel>Password</FormLabel>
        <Link
          as={RouterLink}
          to="/login"
          colorScheme={'blue'}
          hidden={!showForgotPassword}
          fontSize="sm"
        >
          {/* TODO: Add forgot password support */}
          Forgot Password?
        </Link>
      </Flex>
      <Box>
        <InputGroup>
          <Input
            ref={fieldRef}
            id="password-input"
            type={show ? 'text' : 'password'}
            autoComplete={autoComplete}
            required
            enterKeyHint="go"
            value={value}
            onChange={onChange}
            onKeyPress={onKeyPress}
            minLength={12}
            pr={70}
            fontFamily={show ? 'monospace' : 'inherit'}
            data-lpignore={true}
          />
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={handleClick} colorScheme="gray">
              {show ? 'Hide' : 'Show'}
            </Button>
          </InputRightElement>
        </InputGroup>
      </Box>
      <FormErrorMessage>{fieldRef.current?.validationMessage}</FormErrorMessage>
    </FormControl>
  );
};
