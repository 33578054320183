import { client } from 'src';
import * as Sentry from '@sentry/react';
import type { useNavigate } from 'react-router-dom';
import { createStandaloneToast } from '@chakra-ui/react';

export const handleLogin = (
  navigate: ReturnType<typeof useNavigate>,
  redirectPath = '/overview',
) => {
  // NOTE: This is strictly used for display purposes, source of truth is a JWT token in an HTTP only cookie
  localStorage.setItem('isLoggedIn', 'true');
  navigate(redirectPath);
};

export const handleLogout = ({
  path = '/login',
  params,
  navigate,
}: {
  path?: string;
  params?: Record<string, string>;
  navigate?: ReturnType<typeof useNavigate>;
}) => {
  client.clearStore().then(() => {
    localStorage.clear();
    Sentry.setUser(null);
    createStandaloneToast().toast.closeAll();

    if (navigate) {
      navigate({ pathname: path, search: new URLSearchParams(params).toString() });
    } else {
      const url = new URL(window.location.origin);
      url.pathname = path;

      if (params) {
        for (const [key, value] of Object.entries(params)) {
          url.searchParams.set(key, value);
        }
      }

      window.location.href = url.href;
    }
  });
};
