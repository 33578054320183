/**
 * In order for BigInt to work properly with JSON.stringify(), we need to monkey patch the toJSON method. This is primarily needed to work correctly with Apollo's cache
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/BigInt#use_within_json
 */
// @ts-ignore
BigInt.prototype.toJSON = function () {
  return this.toString();
};

export type {};
