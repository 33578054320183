import { gql } from 'src/graphql/__generated__/gql';
import {
  ExternalPlatform,
  type GetOverviewElementsQuery,
  SyncMode,
} from 'src/graphql/__generated__/graphql';

export const GET_OVERVIEW_ELEMENTS = gql(/* GraphQL */ `
  query GetOverviewElements {
    overviewElements {
      items {
        id
        lastSyncedAt
        expiresAt
        institutionName
        externalPlatformType
        elements {
          id
          nickname
          type
          balance
          currencyCode
          syncMode
        }
      }
    }
  }
`);

export const GET_OVERVIEW_ELEMENTS_PLACEHOLDER: GetOverviewElementsQuery = {
  overviewElements: {
    items: [
      {
        id: 'item-123',
        lastSyncedAt: new Date('2023-01-01'),
        expiresAt: null,
        institutionName: 'Chase',
        externalPlatformType: ExternalPlatform.Plaid,
        elements: [
          {
            id: '123',
            nickname: 'Credit Card',
            type: 'ACCOUNT',
            balance: 1000n,
            currencyCode: 'USD',
            syncMode: SyncMode.Automatic,
          },
          {
            id: '456',
            nickname: 'Credit Card',
            type: 'ACCOUNT',
            balance: 1000n,
            currencyCode: 'ARS',
            syncMode: SyncMode.Automatic,
          },
        ],
      },
      {
        id: 'asset',
        lastSyncedAt: new Date('2023-01-01'),
        expiresAt: null,
        institutionName: null,
        externalPlatformType: ExternalPlatform.Manual,
        elements: [
          {
            id: '789',
            nickname: '401k',
            type: 'ASSET',
            balance: 10000000n,
            currencyCode: 'USD',
            syncMode: SyncMode.Manual,
          },
        ],
      },
    ],
  },
};
