import { utc } from 'dayjs';

/** Generate a filtered link to transactions page for the budgeted category */
export const generateTransactionsFilteredLink = ({
  categoryId,
  beforeDate,
  afterDate,
}: {
  categoryId: string | null;
  beforeDate: Date;
  afterDate: Date;
}) => {
  const searchParams = new URLSearchParams();

  searchParams.set('category', categoryId ?? 'uncategorized');
  searchParams.set('dateAfter', utc(afterDate).format('YYYY-MM-DD'));
  searchParams.set('dateBefore', utc(beforeDate).format('YYYY-MM-DD'));

  return `/transactions?${searchParams.toString()}`;
};
