import { gql } from 'src/graphql/__generated__/gql';

export const GET_INCOME_VS_EXPENSE = gql(/* GraphQL */ `
  query GetIncomeVsExpense(
    $startDate: DateTime!
    $endDate: DateTime!
    $interval: AggregationInterval!
  ) {
    incomeVsExpense(startDate: $startDate, endDate: $endDate, interval: $interval) {
      xLabels
      startDate
      endDate
      aggregations {
        category {
          id
          name
          color
        }
        amounts
      }
    }
  }
`);
