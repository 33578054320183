import { gql } from '@apollo/client';

export const CREATE_CATEGORY = gql`
  mutation CreateCategory($name: String!, $color: String!, $groupId: String!) {
    createCategory(name: $name, color: $color, groupId: $groupId) {
      id
      color
      name
    }
  }
`;

export interface CreateCategoryInput {
  name: string;
  color: string;
  groupId: string;
}

export interface CreateCategoryData {
  __typename: 'Mutation';
  createCategory: {
    id: string;
    color: string;
    name: string;
  };
}
