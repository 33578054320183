import { useReactiveVar } from '@apollo/client';
import { Select } from '@chakra-ui/react';
import { selectedChart } from 'src/cache';

export enum ChartType {
  INCOME_VS_EXPENSE = 'Income vs Expense',
  SPEND_BY_CATEGORY = 'Spend By Category',
}

export function ChartSelect() {
  const selectedChartValue = useReactiveVar(selectedChart);

  return (
    <Select
      variant={'filled'}
      flex={1}
      size={{ base: 'sm', md: 'md' }}
      onChange={(e) => selectedChart(e.target.value as ChartType)}
      defaultValue={selectedChartValue}
      boxShadow="base"
      maxW={'fit-content'}
    >
      {Object.values(ChartType).map((type) => (
        <option key={type}>{type}</option>
      ))}
    </Select>
  );
}
