import { Container, Flex } from '@chakra-ui/react';
import { useReactiveVar } from '@apollo/client';

import { AppRoot } from 'src/components/shared/AppRoot';
import { GraphInterval, YearPicker } from './YearPicker';
import { ChartSelect, ChartType } from './ChartSelect';
import { graphInterval, selectedChart } from 'src/cache';
import { IncomeExpenseArea } from './charts/IncomeVsExpense';
import { CategoryPie } from './charts/CategoryPie';
import { CategoryBar } from './charts/CategoryBar';
import { HeadingWithMenu } from 'src/components/shared/HeadingWithMenu';

function Chart(selectedChartValue: ChartType, graphIntervalValue: GraphInterval) {
  switch (selectedChartValue) {
    case ChartType.INCOME_VS_EXPENSE:
      return <IncomeExpenseArea />;
    case ChartType.SPEND_BY_CATEGORY:
      switch (graphIntervalValue) {
        case GraphInterval.LAST_MONTH:
        case GraphInterval.MONTH_TO_DATE: {
          return <CategoryPie />;
        }
        default: {
          return <CategoryBar />;
        }
      }
  }
}

export function InsightsApp() {
  const selectedChartValue = useReactiveVar(selectedChart);
  const graphIntervalValue = useReactiveVar(graphInterval);

  return (
    <AppRoot>
      <Flex flexDirection="column" flexGrow={1} maxW={'100ch'} gap={{ base: 2, md: 4 }}>
        <Flex gap={2} alignItems="center">
          <HeadingWithMenu showBreadcrumbs={false} />
          <YearPicker />
          <ChartSelect />
        </Flex>
        <Container padding={4} flex={1} maxWidth={1000} variant={'card'}>
          {Chart(selectedChartValue, graphIntervalValue)}
        </Container>
      </Flex>
    </AppRoot>
  );
}
