import { gql } from '@apollo/client';

export const SET_CATEGORY_BUDGET = gql`
  mutation SetCategoryBudget($amount: Float!, $categoryId: String!, $budgetId: String!) {
    setCategoryBudget(amount: $amount, categoryId: $categoryId, budgetId: $budgetId) {
      id
      category {
        id
      }
      budget {
        id
      }
      budgeted
    }
  }
`;

export interface SetCategoryBudgetInput {
  budgetId: string;
  categoryId: string;
  amount: number;
}

export interface SetCategoryBudgetData {
  setCategoryBudget: {
    id: string;
    budget: { id: string; __typename: 'Budget' };
    category: { id: string; __typename: 'Category' };
    budgeted: number;
    __typename: 'CategoryBudget';
  };
}
