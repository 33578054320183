import { gql } from '@apollo/client';

export const CREATE_MANUAL_TRANSFER = gql`
  mutation CreateManualTransfer($inflowTransactionId: String!, $outflowTransactionId: String!) {
    createManualTransfer(
      inflowTransactionId: $inflowTransactionId
      outflowTransactionId: $outflowTransactionId
    ) {
      id
      inflowTransaction {
        id
      }
      outflowTransaction {
        id
      }
    }
  }
`;

export interface CreateManualTransferInput {
  inflowTransactionId: string;
  outflowTransactionId: string;
}

export interface CreateManualTransferData {
  createManualTransfer: {
    __typename: 'Transfer';
    id: string;
    inflowTransaction: {
      id: string;
    };
    outflowTransaction: {
      id: string;
    };
  };
}
