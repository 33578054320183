import { gql } from 'src/graphql/__generated__/gql';
import type {
  ResetItemExpiresAtMutation,
  ResetItemExpiresAtMutationVariables,
} from 'src/graphql/__generated__/graphql';
import type { GraphqlMutation } from 'src/util/graphql';

export interface IResetItemExpiresAt {
  input: ResetItemExpiresAtMutationVariables;
  output: ResetItemExpiresAtMutation;
}

export const ResetItemExpiresAt: GraphqlMutation<
  IResetItemExpiresAt,
  {
    itemId: string;
  }
> = {
  query: gql(/* GraphQL */ `
    mutation ResetItemExpiresAt($itemId: String!) {
      resetItemExpiresAt(itemId: $itemId) {
        id
        expiresAt
      }
    }
  `),
  optimisticResponse: ({ itemId }) => ({
    resetItemExpiresAt: {
      __typename: 'Item',
      id: itemId,
      expiresAt: null,
    },
  }),
  update: (cache, { data }, { variables }) => {
    if (data === undefined || data == null || variables === undefined) {
      return;
    }

    cache.modify({
      id: cache.identify({ __typename: 'OverviewItem', id: variables.itemId }),
      fields: {
        expiresAt() {
          return null;
        },
      },
    });
  },
};
