import {
  Button,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalBody,
  ModalFooter,
  AlertDialogHeader,
  FormLabel,
  FormControl,
  FormErrorMessage,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface CategoryBudgetModalProps {
  categoryName: string;
  isEdit: boolean;
  currentBudgeted: number | undefined;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (amount: number) => void;
}

const format = (val: string) => `$${val}`;
const parse = (val: string) => val.replace(/^\$/, '');

export function CategoryBudgetModal({
  categoryName,
  currentBudgeted,
  isEdit,
  isOpen,
  onClose,
  onConfirm,
}: CategoryBudgetModalProps) {
  const { t } = useTranslation();
  const action = isEdit ? 'Edit' : 'Create';
  const [amount, setAmount] = useState<string>(((currentBudgeted ?? 0) / 100).toFixed(2));
  const [isInvalid, setIsInvalid] = useState<boolean>(false);

  const save = () => {
    const parsedAmount = Number.parseFloat(amount) * 100;
    if (Number.isNaN(parsedAmount)) {
      setIsInvalid(true);
    } else {
      onConfirm(parsedAmount);
      setIsInvalid(false);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          {`${action} "${categoryName}" Budget`}
        </AlertDialogHeader>

        <ModalBody>
          <FormControl id="budgeted" marginBottom={3} isInvalid={isInvalid}>
            <FormLabel>{t('budget.modal.budgetLabel')}</FormLabel>
            <NumberInput
              variant="filled"
              defaultValue={100}
              precision={2}
              step={10}
              min={0}
              max={100_000}
              value={format(amount)}
              onChange={(valueString) => {
                setAmount(parse(valueString));
              }}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  save();
                }
              }}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <FormErrorMessage>{t('budget.modal.invalidAmountErrorText')}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalCloseButton />

        <ModalFooter>
          <Button onClick={save}>{action}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
