import { useMutation } from '@apollo/client';
import { Button, Flex } from '@chakra-ui/react';
import { useRef, useState } from 'react';
import {
  InputField,
  NumberField,
  SelectField,
} from 'src/components/overview/createAccountFlow/CreateEditAccountForm';
import { CreateAsset, type ICreateAsset } from 'src/graphql/CreateAsset';
import { AssetType } from 'src/graphql/__generated__/graphql';

type SetupAssetAccountProps = {
  onClose: () => void;
};

export const SetupAssetAccount = ({ onClose }: SetupAssetAccountProps) => {
  const formRef = useRef<HTMLFormElement>(null);

  const [name, setName] = useState<string>('');
  const [type, setType] = useState<string>(AssetType.Investment);
  const [balance, setBalance] = useState<bigint>(0n);
  const [currencyCode, setCurrencyCode] = useState<string>('USD');
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState<boolean>(false);

  const [createAsset, { loading }] = useMutation<ICreateAsset['output'], ICreateAsset['input']>(
    CreateAsset.query,
    {
      optimisticResponse: CreateAsset.optimisticResponse({
        id: crypto.randomUUID(),
      }),
      update: CreateAsset.update,
    },
  );

  const submit = () => {
    setHasBeenSubmitted(true);

    if (formRef.current?.checkValidity()) {
      createAsset({
        variables: {
          name,
          type: type as AssetType,
          currentBalance: balance,
          currencyCode,
        },
      }).then(onClose);
    }
  };

  return (
    <form ref={formRef}>
      <Flex gap={4} flexDirection="column">
        <InputField
          fieldId="name"
          label={'Name'}
          placeholder="Company 401k"
          value={name}
          minLength={1}
          onChange={(v) => setName(v)}
          hasBeenSubmitted={hasBeenSubmitted}
        />
        <SelectField
          fieldId="type"
          label={'Account Type'}
          value={type}
          onChange={(v) => setType(v)}
          hasBeenSubmitted={hasBeenSubmitted}
          options={Object.values(AssetType)}
        />
        <SelectField
          fieldId="type"
          label={'Currency'}
          value={currencyCode}
          onChange={(v) => setCurrencyCode(v)}
          hasBeenSubmitted={hasBeenSubmitted}
          options={Object.values(CurrencyCode)}
          valueFormatter={(val: string) => val}
        />
        <NumberField
          fieldId="currentBalance"
          label={'Current Balance'}
          helperText="The balance or value of the asset as of today"
          value={balance.toString()}
          currencyCode={currencyCode}
          onChange={(v) => setBalance(BigInt(v))}
          hasBeenSubmitted={hasBeenSubmitted}
        />
        <Button
          alignSelf={'flex-end'}
          onClick={submit}
          isDisabled={hasBeenSubmitted && !formRef.current?.checkValidity()}
          isLoading={loading}
        >
          Create
        </Button>
      </Flex>
    </form>
  );
};

enum CurrencyCode {
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  AUD = 'AUD',
  AWG = 'AWG',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BRL = 'BRL',
  BSD = 'BSD',
  BTN = 'BTN',
  BWP = 'BWP',
  BYN = 'BYN',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CHF = 'CHF',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  CRC = 'CRC',
  CUC = 'CUC',
  CUP = 'CUP',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EGP = 'EGP',
  ERN = 'ERN',
  ETB = 'ETB',
  EUR = 'EUR',
  FJD = 'FJD',
  FKP = 'FKP',
  GBP = 'GBP',
  GEL = 'GEL',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRU = 'MRU',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PYG = 'PYG',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SLL = 'SLL',
  SOS = 'SOS',
  SRD = 'SRD',
  SSP = 'SSP',
  STN = 'STN',
  SVC = 'SVC',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  USD = 'USD',
  UYU = 'UYU',
  UZS = 'UZS',
  VES = 'VES',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XAF = 'XAF',
  XCD = 'XCD',
  XOF = 'XOF',
  XPF = 'XPF',
  YER = 'YER',
  ZAR = 'ZAR',
  ZMW = 'ZMW',
  ZWL = 'ZWL',
}
