import {
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  type useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { ChooseAccountType } from './ChooseAccountType';
import { DotProgressIndicator } from 'src/components/shared/DotProgressIndicator';
import { CsvUploadForm, type FieldMapping } from 'src/components/csvUpload/CsvUploadForm';
import { CreateAutomaticAccount } from './CreateAutomaticAccount';
import { useMutation } from '@apollo/client';
import { strToSha256, type CsvFile } from 'src/components/csvUpload/FileSelect';
import { SetupAssetAccount } from 'src/components/overview/createAccountFlow/SetupAssetAccount';
import { SetupEmailAccount } from 'src/components/overview/createAccountFlow/emailAccount/SetupEmailAccount';
import { ForwardInstructionsEmailAccount } from 'src/components/overview/createAccountFlow/emailAccount/ForwardInstructionsEmailAccount';
import { FIND_OR_CREATE_CATEGORIES_BY_NAME_MUTATION } from 'src/graphql/FindOrCreateCategoriesByName';
import { FIND_OR_CREATE_ACCOUNTS_BY_NAME_MUTATION } from 'src/graphql/FindOrCreateAccountsByName';
import { AccountType, type CreateManualTransactionInput } from 'src/graphql/__generated__/graphql';
import { CREATE_TRANSACTIONS_BATCH_MUTATION } from 'src/graphql/CreateTransactionsBatch';
import { parseAmount } from 'src/util';
import { cache } from 'src/cache';

interface CreateAccountModalProps {
  isOpen: boolean;
  onCloseModal: ReturnType<typeof useDisclosure>['onClose'];
}

export enum CreateAccountType {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL',
  Asset = 'ASSET',
  Email = 'EMAIL',
}

export const CreateAccountModal = ({ isOpen, onCloseModal }: CreateAccountModalProps) => {
  const [plaidLinkOpen, setPlaidLinkOpen] = useState(false);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [accountType, setAccountType] = useState<CreateAccountType>(CreateAccountType.Manual);
  const [csvFiles, setCsvFiles] = useState<CsvFile[] | undefined>(undefined);
  const [fieldMapping, setFieldMapping] = useState<FieldMapping>({
    Merchant: undefined,
    Payee: undefined,
    Amount: undefined,
    Date: undefined,
    Account: undefined,
    Category: undefined,
    Notes: undefined,
    Type: undefined,
  });
  const [venmoAccountId, setVenmoAccountId] = useState<string>();

  // Reset the modal state when it closes
  const onClose = useCallback(() => {
    setActiveIndex(0);
    setCsvFiles(undefined);
    setFieldMapping({
      Merchant: undefined,
      Payee: undefined,
      Amount: undefined,
      Date: undefined,
      Account: undefined,
      Category: undefined,
      Notes: undefined,
      Type: undefined,
    });
    onCloseModal();
  }, [onCloseModal]);

  const [findOrCreateCategoriesByName, { loading: createCategoriesLoading }] = useMutation(
    FIND_OR_CREATE_CATEGORIES_BY_NAME_MUTATION,
  );

  const [findOrCreateAccountsByName, { loading: createAccountsLoading }] = useMutation(
    FIND_OR_CREATE_ACCOUNTS_BY_NAME_MUTATION,
  );

  const [createTransactionsBatch, { loading: createTransactionsLoading }] = useMutation(
    CREATE_TRANSACTIONS_BATCH_MUTATION,
  );

  // Hide the modal if the Plaid modal is open1
  const modalVisibility = plaidLinkOpen ? 'hidden' : 'inherit';

  const createManualAccounts = async () => {
    if (csvFiles === undefined) {
      return;
    }

    const accountField = fieldMapping.Account;
    const categoryField = fieldMapping.Category;
    const merchantField = fieldMapping.Merchant;
    const payeeField = fieldMapping.Payee;
    const amountField = fieldMapping.Amount;
    const dateField = fieldMapping.Date;
    const notesField = fieldMapping.Notes;
    const typeField = fieldMapping.Type;

    // Ensure none of the fields are undefined
    if (
      accountField === undefined ||
      categoryField === undefined ||
      merchantField === undefined ||
      payeeField === undefined ||
      amountField === undefined ||
      dateField === undefined ||
      notesField === undefined ||
      typeField === undefined
    ) {
      return;
    }

    // Extract unique account names from CSV files
    const uniqueAccountNames = new Set<string>();
    for (const csvFile of csvFiles) {
      for (const row of csvFile.data) {
        const accountName = row[accountField];
        if (accountName) {
          uniqueAccountNames.add(accountName);
        }
      }
    }

    // Find or create all unique accounts from the CSV file
    const { data: accountsData } = await findOrCreateAccountsByName({
      variables: {
        createAccountInputs: Array.from(uniqueAccountNames).map((accountName) => ({
          nickname: accountName,
          type: AccountType.Unknown,
        })),
      },
    });

    const accountNameToId = new Map<string, string>();
    if (accountsData?.findOrCreateAccounts) {
      for (const account of accountsData.findOrCreateAccounts) {
        accountNameToId.set(account.nickname, account.id);
      }
    }

    // Extract unique categories from CSV files
    const uniqueCategories = new Set<string>();
    for (const csvFile of csvFiles) {
      for (const row of csvFile.data) {
        const category = row[categoryField];
        if (category) {
          uniqueCategories.add(category);
        }
      }
    }

    // Find or create all unique categories from the CSV file
    const { data: categoriesData } = await findOrCreateCategoriesByName({
      variables: {
        categoryGroupName: 'Imported',
        categoryNames: Array.from(uniqueCategories),
      },
    });

    const categoryNameToId = new Map<string, string>();
    if (categoriesData?.findOrCreateCategoriesByName) {
      for (const category of categoriesData.findOrCreateCategoriesByName) {
        categoryNameToId.set(category.name, category.id);
      }
    }

    const transactionsMap = new Map<string, CreateManualTransactionInput[]>();
    for (const csvFile of csvFiles) {
      for (const [rowIdx, row] of csvFile.data.entries()) {
        const accountId = accountNameToId.get(row[accountField]);

        if (accountId === undefined) {
          continue;
        }

        const category = categoryNameToId.get(row[categoryField]);

        const transaction = {
          category: category ? { id: category } : undefined,
          payee: row[merchantField],
          rawPayee: row[payeeField],
          amount:
            BigInt(parseAmount(row[amountField])) *
            BigInt(row[typeField].toLowerCase() === 'debit' ? -1 : 1),
          date: new Date(row[dateField]),
          notes: row[notesField],
          currencyCode: 'USD',
          // Use a consistent hash of the CSV row (and row index to avoid merging multiple same day transactions for the same amount + vendor) for the external ID
          externalId: await strToSha256(JSON.stringify({ ...row, rowIdx })),
        };

        if (transactionsMap.has(accountId)) {
          const currentTs = [...(transactionsMap.get(accountId) ?? []), transaction];
          transactionsMap.set(accountId, currentTs);
        } else {
          transactionsMap.set(accountId, [transaction]);
        }
      }
    }

    for (const [accountId, transactions] of transactionsMap.entries()) {
      await createTransactionsBatch({
        variables: {
          accountId,
          transactions,
        },
      });
    }

    // Refetch the overview page data
    cache.evict({ id: 'ROOT_QUERY', fieldName: 'transactions' });
    cache.evict({ id: 'ROOT_QUERY', fieldName: 'overviewElements' });
    cache.evict({ id: 'ROOT_QUERY', fieldName: 'netWorthHistory' });
    cache.evict({ id: 'ROOT_QUERY', fieldName: 'categoryGroups' });
    cache.gc();

    // Close the modal and reset the form
    onClose();
  };

  const Pages: Record<number, { title: string; component: JSX.Element }> = {
    [0]: {
      title: 'Choose Account Type',
      component: (
        <ChooseAccountType setActiveIndex={setActiveIndex} setAccountType={setAccountType} />
      ),
    },
    [1]: {
      title: (() => {
        switch (accountType) {
          case CreateAccountType.Manual: {
            return 'Setup Account';
          }
          case CreateAccountType.Automatic: {
            return 'Setup Connection';
          }
          case CreateAccountType.Asset: {
            return 'Setup Asset';
          }
          case CreateAccountType.Email: {
            return 'Setup Venmo';
          }
        }
      })(),
      component: (() => {
        switch (accountType) {
          case CreateAccountType.Manual:
            return (
              <CsvUploadForm
                csvFiles={csvFiles}
                setCsvFiles={setCsvFiles}
                fieldMapping={fieldMapping}
                setFieldMapping={setFieldMapping}
                onSubmit={createManualAccounts}
                submitting={
                  createCategoriesLoading || createAccountsLoading || createTransactionsLoading
                }
              />
            );
          case CreateAccountType.Automatic:
            return <CreateAutomaticAccount setPlaidLinkOpen={setPlaidLinkOpen} onClose={onClose} />;
          case CreateAccountType.Asset:
            return <SetupAssetAccount onClose={onClose} />;
          case CreateAccountType.Email:
            return (
              <SetupEmailAccount
                next={(accountId) => {
                  setVenmoAccountId(accountId);
                  setActiveIndex(2);
                }}
              />
            );
        }
      })(),
    },
    [2]: {
      title: (() => {
        switch (accountType) {
          case CreateAccountType.Manual: {
            return 'Import CSV Statements';
          }
          case CreateAccountType.Email: {
            return 'Setup Email Forwarding Rule';
          }
          default: {
            return '';
          }
        }
      })(),
      component: (() => {
        switch (accountType) {
          case CreateAccountType.Email: {
            return (
              <ForwardInstructionsEmailAccount
                onClose={onClose}
                venmoAccountId={venmoAccountId as string}
              />
            );
          }
          default: {
            return <></>;
          }
        }
      })(),
    },
  };

  const currentPage = Pages[activeIndex];

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      closeOnOverlayClick={false}
      trapFocus={!plaidLinkOpen}
    >
      <ModalOverlay visibility={modalVisibility} />
      <ModalContent visibility={modalVisibility}>
        <ModalHeader display="flex" alignItems={'center'} gap={1}>
          <IconButton
            colorScheme={'gray'}
            variant="ghost"
            hidden={activeIndex === 0}
            aria-label="Back"
            icon={<ChevronLeftIcon w={6} h={6} />}
            onClick={() => setActiveIndex(activeIndex - 1)}
            size={'sm'}
          />
          {currentPage.title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" flexDir="column" justifyContent="center" gap={8}>
          {currentPage.component}
        </ModalBody>
        <ModalFooter justifyContent={'center'}>
          <DotProgressIndicator activeIndex={activeIndex} stepCount={3} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
