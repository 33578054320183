import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation CreateUser($name: String!, $email: String!, $password: String!) {
    createUser(name: $name, email: $email, password: $password) {
      id
      name
      email
    }
  }
`;

export interface CreateUserInput {
  name: string;
  email: string;
  password: string;
}

export interface CreateUserPayload {
  createUser: {
    id: string;
    name: string;
    email: string;
  };
}
